import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import _ from "lodash";
import DatePicker from 'react-16-bootstrap-date-picker';
import { addDays } from 'date-fns';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { SyncLoader } from 'react-spinners';
import matchSorter from 'match-sorter';

var clientsList = [];

class Planned extends Component {
    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
        this.renderEditable = this.renderEditable.bind(this);
    }
    renderEditable(cellInfo) {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const rows = [...this.state.rows];
                    rows[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ rows });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.rows[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    login() {
        this.props.auth.login();
    }

    componentWillMount() {

        var user = localStorage.getItem('userProfileName');

        this.setState({ formattedValue: null });
        this.setState({ value: '' });
        this.setState({ plocklista: [] });
        this.setState({
            author: user
        });
        this.setState({
            isLoading: false,
            selection: [],
            selectAll: false,
            drivercount: 0,
            duration: 3,
            pageSize: 50
        });
        this.setState({
            showModal: false,
            selectedclients: [],
            errors: []
        });

        this.setState({ clients: [] });
        this.setState({
            deliveryGroups: [
                // { value: 'Gordon Sthlm', label: 'Gordon Sthlm' },
                // { value: 'Gordon Gbg', label: 'Gordon Gbg' },
                // { value: 'Gordon Mma', label: 'Gordon Mma' },
                // { value: 'Gordon Ostergotland', label: 'Gordon Ostergotland' },
                // { value: 'Gordon Eskilstuna', label: 'Gordon Eskilstuna' },
                // { value: "Gordon Ljungby", label: "Gordon Ljungby" },
                // { value: 'Gordon Sjælland', label: 'Gordon Sjælland' },
                // { value: 'Gordon Aaborg', label: 'Gordon Aaborg' },
                // { value: 'Gordon Fredericia', label: 'Gordon Fredericia' },
                // { value: 'Gordon Århus', label: 'Gordon Århus' },]

                { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Tumba" },
                { value: "61ee68c02bf9b60bbcbe7d63", label: "Gordon Stockholm Årsta" },
                { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Västberga" },
                { value: "648064d45a639704741f519e", label: "Gordon Stockholm Tumba Temp" },
                { value: "5efcabc203fd75517c04fb39", label: "Gordon Göteborg" },
                { value: "5efcabb103fd75517c04fb38", label: "Gordon Malmö" },
                { value: '5efcac8903fd75517c04fb3b', label: 'Gordon Linköping' },
                { value: '61695c442d8ad20f37a40179', label: 'Gordon Karlstad' },
                { value: '5efcacf603fd75517c04fb3e', label: 'Gordon København, DK' },
                { value: '5efcae2d03fd75517c04fb42', label: 'Gordon Aalborg, DK' },
                { value: '5efcacdb03fd75517c04fb3d', label: 'Gordon Fredericia, DK' },
                { value: '5efcad0703fd75517c04fb3f', label: 'Gordon Århus, DK' },
                { value: '5efcacc303fd75517c04fb3c', label: 'Gordon Eskilstuna' },
                { value: '602b8e0464849a0bb91dbec5', label: 'Gordon Uppsala' },

                { value: "5f71b3f408755a0c6592c78a", label: "Gordon Halmstad" },
                { value: "5f71b3d9d4ecbe0c79a0d28f", label: "Gordon Jönköping" },
                { value: "5f71b3b708755a0c6592c782", label: "Gordon Växjö" },
                { value: "5efcac3303fd75517c04fb3a", label: "Gordon Ljungby" },
                { value: "62a08775ce90de0beb76b28c", label: "Gordon Karlshamn" },
                { value: "630dd5385c0c3b0c06292014", label: "Gordon hissing kärra" },
                { value: "641a0370bf36d430617eaab5", label: "Gordon Gävle" },
                { value: "633aa933d74ce70b8d0e9a6a", label: "Gordon Sundsvall" }]
        });
        this.setState({ clientname: '' });
        this.setState({ statuses: ['New', 'Planned', 'Routed', 'Assigned', 'Unassigned', 'Dispatched', 'Deleted', 'Cancelled', 'Done'] });
        this.setState({ user: '' });

        this.state = {
            dateRange: {
                selection: {
                    startDate: addDays(new Date(), -7),
                    endDate: addDays(new Date(), 7),
                    key: 'selection',
                },
            }
        };

        this.setState({
            dateRange: {
                selection: {
                    startDate: addDays(new Date(), -7),
                    endDate: addDays(new Date(), 7),
                    key: 'selection',
                },
            }
        });

        let toggleValue = [];

        this.setState({ toggleValue: toggleValue });
        this.setState({ selectedDeliveryGroup: null });

        this.fetchData = this.fetchData.bind(this);
        this.setState({ rows: [] });
        this.setState({ originalrows: [] });
    }

    componentDidMount() {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {
            var url = process.env.REACT_APP_GORDON_API_URL + "/client/active";

            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((resp) => resp.json())
                .then((dataJson) => {
                    var clients = dataJson;
                    clientsList = [];
                    for (let i = 0; i < clients.length; i++) {
                        clientsList.push({ value: clients[i]._id, label: clients[i].name });
                    }
                    const sorter = (a, b) => {
                        if (a.label > b.label) return 1;
                        if (a.label < b.label) return -1;
                        return 0;
                    };

                    clientsList = clientsList.sort(sorter);

                    this.state.clients = clientsList;
                    this.setState({ clients: clientsList });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    getOrders(merchant, startDate) {
        this.setState({
            isLoading: true
        });
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/planning/planned?merchant=' + merchant + '&date=' + moment(startDate).format("YYYY-MM-DD"), {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var items = [];
                    for (var i = 0; i < responseJson.length; i++) {
                        responseJson[i].created_at = moment(responseJson[i].created_at).format("YYYY-MM-DD HH:mm");
                        responseJson[i].updated_at = moment(responseJson[i].updated_at).format("YYYY-MM-DD HH:mm");
                        responseJson[i].promisedTime = moment(responseJson[i].orderInfo.promisedTime).format("HH:mm");
                        responseJson[i].deliveryDate = moment(responseJson[i].deliveryDate).format("YYYY-MM-DD");
                        responseJson[i].merchant = responseJson[i].pickupInfo.contactInfo.name;
                        responseJson[i].customername = responseJson[i].deliveryAddress.fullName;
                        responseJson[i].customeraddress = responseJson[i].deliveryAddress.streetAddress;
                        responseJson[i].customerzipcode = responseJson[i].deliveryAddress.postalCode;
                        responseJson[i].latitude = responseJson[i].deliveryAddress.latitude;
                        responseJson[i].longitude = responseJson[i].deliveryAddress.longitude;
                        responseJson[i].customercity = responseJson[i].deliveryAddress.city;
                        responseJson[i].entrycode = responseJson[i].deliveryAddress.entryCode;
                        responseJson[i].mobilePhone = responseJson[i].deliveryContactInformation != null ? responseJson[i].deliveryContactInformation.mobilePhone : "";
                        responseJson[i].email = responseJson[i].deliveryContactInformation != null ? responseJson[i].deliveryContactInformation.email : "";
                        responseJson[i].zipcodeApproved = responseJson[i].validation != null ? responseJson[i].validation.zipcodeApproved : null;
                        responseJson[i].zone = responseJson[i].validation != null ? responseJson[i].validation.zone : null;
                        responseJson[i].deliveryGroup = responseJson[i].depot ? responseJson[i].depot.name : responseJson[i].validation != null ? responseJson[i].validation.deliveryGroup : null;
                        responseJson[i].status = responseJson[i].orderInfo.status;
                        responseJson[i].routeName = responseJson[i].orderInfo.route.name;
                        responseJson[i].stopNumber = responseJson[i].orderInfo.stopNumber;

                        if (responseJson[i].items != null && responseJson[i].items.length > 0) {
                            var count = 0;
                            var content = "";
                            var packageType = "";

                            var totalWeight = 0;
                            var dryWeight = 0;
                            var chilledWeight = 0;
                            var frozenWeight = 0;

                            for (let j = 0; j < responseJson[i].items.length; j++) {

                                if (responseJson[i].items[j].weight != null && responseJson[i].items[j].weight > 0) {
                                    totalWeight = totalWeight + responseJson[i].items[j].weight;
                                    if (responseJson[i].items[j].itemType != null && responseJson[i].items[j].itemType == "dry") {
                                        dryWeight = dryWeight + responseJson[i].items[j].weight;
                                    } else if (responseJson[i].items[j].itemType != null && responseJson[i].items[j].itemType == "chilled") {
                                        chilledWeight = chilledWeight + responseJson[i].items[j].weight;
                                    } else if (responseJson[i].items[j].itemType != null && responseJson[i].items[j].itemType == "frozen") {
                                        frozenWeight = frozenWeight + responseJson[i].items[j].weight;
                                    }
                                }

                                count = count + responseJson[i].items[j].numberOfItems;
                                if (j > 0) {
                                    content = content + " \n\r";
                                    packageType = packageType + " | ";
                                }
                                if (responseJson[i].merchant == "GI-Boxen") {
                                    content = content + responseJson[i].items[j].name;
                                    packageType = packageType + responseJson[i].items[j].name;
                                }
                                else if (responseJson[i].merchant == "outofhome.se") {
                                    packageType = packageType + responseJson[i].items[j].itemType + " - " + responseJson[i].items[j].packageType + " (" + responseJson[i].items[j].weight + " kg)";
                                }
                                else {
                                    packageType = packageType + (responseJson[i].items[j].numberOfItems != null ? responseJson[i].items[j].numberOfItems.toString() : "1") + "x " + responseJson[i].items[j].name;
                                }

                                if (responseJson[i].items[j].articles != null && responseJson[i].items[j].articles.length > 0) {
                                    content = content + ":\n\r";
                                    for (let k = 0; k < responseJson[i].items[j].articles.length; k++) {
                                        if (k > 0) {
                                            content = content + " \n\r";
                                        }
                                        items.push({
                                            "name": responseJson[i].items[j].articles[k].name,
                                            "count": responseJson[i].items[j].articles[k].quantity
                                        });
                                        content = content + responseJson[i].items[j].articles[k].quantity + "x " + responseJson[i].items[j].articles[k].name;
                                    }
                                    content = content + " \n\r";
                                }
                            }
                            responseJson[i].numberOfItems = count;
                            responseJson[i].packageType = packageType;
                            responseJson[i].packageContent = content;

                            responseJson[i].totalWeight = totalWeight;
                            responseJson[i].dryWeight = dryWeight;
                            responseJson[i].chilledWeight = chilledWeight;
                            responseJson[i].frozenWeight = frozenWeight;
                        }
                        else {
                            responseJson[i].packageType = responseJson[i].orderInfo.title;
                            responseJson[i].numberOfItems = 1;
                        }
                        var timewindow = moment(responseJson[i].orderInfo.noEarlierThan).format("HH:mm") + " - "
                            + moment(responseJson[i].orderInfo.noLaterThan).format("HH:mm");
                        responseJson[i].timewindow = timewindow;

                        var start = moment(responseJson[i].orderInfo.promisedTime).add(-90, 'minutes');
                        if (start.diff(moment(responseJson[i].orderInfo.noEarlierThan)) < 0) {
                            start = moment(responseJson[i].orderInfo.noEarlierThan);
                        }
                        responseJson[i].start = start.format("HH:mm");
                        var end = moment(responseJson[i].orderInfo.promisedTime).add(90, 'minutes');
                        if (end.diff(moment(responseJson[i].orderInfo.noLaterThan)) > 0) {
                            end = moment(responseJson[i].orderInfo.noLaterThan);
                        }
                        responseJson[i].end = end.format("HH:mm");
                    }

                    var plocklista = [];
                    plocklista = [];
                    items.reduce(function (res, value) {
                        if (!res[value.name]) {
                            res[value.name] = {
                                count: 0,
                                name: value.name
                            };
                            plocklista.push(res[value.name])
                        }
                        res[value.name].count += value.count
                        return res;
                    }, {});

                    const sorter = (a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    };

                    plocklista = plocklista.sort(sorter);

                    let rows = responseJson;
                    this.setState({ plocklista: plocklista });
                    this.state.rows = rows;
                    this.setState({ rows: rows });
                    this.state.originalrows = responseJson;
                    this.setState({ originalrows: responseJson });
                    this.setState({
                        isLoading: false,
                        pageSize: rows.length
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                });

        } else {
            console.error("401 Unauthorized");
            this.setState({
                isLoading: false
            });
        }
    }
    fetchData(state, instance) {
        let filteredData = this.state.originalrows;
        if (state.filtered.length) {
            filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
                return filteredSoFar.filter(row => {
                    return (row[nextFilter.id] + "").includes(nextFilter.value);
                });
            }, filteredData);
        }
        // You can also use the sorting in your request, but again, you are responsible for applying it.
        const sortedData = _.orderBy(
            filteredData,
            state.sorted.map(sort => {
                return row => {
                    if (row[sort.id] === null || row[sort.id] === undefined) {
                        return -Infinity;
                    }
                    return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                };
            }),
            state.sorted.map(d => (d.desc ? "desc" : "asc"))
        );

        var result = [];
        for (var i = 0; i < sortedData.length; i++) {
            if (moment().add(-7, 'days').isBefore(moment(sortedData[i].deliveryDate)) &&
                moment(sortedData[i].deliveryDate).isBefore(moment())) {
                result.push(sortedData[i]);
            }
        }
        let rows = sortedData;
        this.state.rows = rows;
        this.setState({ rows: rows });
    }

    handleRangeChange(which, payload) {
        console.log(which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });

    }

    cancel() {
        this.setState({
            showModal: false
        });
    }

    routeClick() {
        var rows = this.state.rows;
        var selectedclients = [];
        var errors = [];
        for (let index = 0; index < rows.length; index++) {
            const cell = rows[index];
            if (this.state.selection.indexOf(cell._id) >= 0) {

                if (selectedclients.indexOf(cell.merchant) < 0) {
                    selectedclients.push(cell.merchant);
                }
                if (cell.zipcodeApproved != 'OK') {
                    if (cell.zipcodeApproved == 'Ogiltig') {
                        errors.push("Order ID " + cell.external_ref + " med postnummer " + cell.customerzipcode + " är inte giltigt inom vald Delivery Group.");
                    }
                    if (cell.zipcodeApproved == 'Inget postnummer') {
                        errors.push("Order ID " + cell.external_ref + " saknar postnummer.");
                    }
                }
            }
        }
        this.setState({
            errors: errors,
            drivercount: Math.round(this.state.selection.length / 30) == 0 ? 1 : Math.round(this.state.selection.length / 30)
        });
        this.setState({
            showModal: true,
            selectedclients, selectedclients,
        });
    }

    searchClick() {
        var merchant = this.state.toggleValue != null ? this.state.toggleValue.map(e => e.value).join(",") : "";
        this.setState({ merchant: merchant });
        this.setState({ selection: [] });

        this.getOrders(merchant, this.state.formattedValue);
    }

    durationChanged = (evt) => {
        const duration = evt.target.value;
        this.setState({ duration });
    }

    driversChanged = (evt) => {
        const drivercount = evt.target.value;
        this.setState({ drivercount });
    }
    handleDeliveryGroupChange = (selectedOption) => {
        if (selectedOption != null) {

            this.setState({
                selectedDeliveryGroup: selectedOption.value
            });

        }
        else {
            this.setState({
                selectedDeliveryGroup: null
            });

        }
    }
    handleChange = (selectedOption) => {
        if (selectedOption != null) {

            this.setState({
                toggleValue: selectedOption
            });

        }
        else {
            this.setState({
                toggleValue: []
            });

        }
    }

    handleDateChange(value, formattedValue) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        });

    }


    render() {

        var { statuses, clients, deliveryGroups } = this.state;
        if (statuses == null) {
            statuses = [];
        }
        if (clients == null) {
            clients = [];
        }
        if (deliveryGroups == null) {
            deliveryGroups = [];
        }
        const columns = [

            {
                Header: 'Butik/Kund',
                accessor: 'merchant',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["merchant"] }),
                filterAll: true
            },
            {
                Header: 'Order ID',
                accessor: 'external_ref',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["external_ref"] }),
                filterAll: true
            },
            {
                Header: 'Leveransdatum',
                accessor: 'deliveryDate',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["deliveryDate"] }),
                filterAll: true
            },
            {
                Header: 'Leveransintervall',
                accessor: 'timewindow',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["timewindow"] }),
                filterAll: true
            },
            {
                Header: 'Planerad leveranstid',
                accessor: 'promisedTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["promisedTime"] }),
                filterAll: true
            },
            {
                Header: 'Kund - namn',
                accessor: 'customername',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customername"] }),
                filterAll: true,
            },

            {
                Header: 'Kund - leveransadress',
                accessor: 'customeraddress',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customeraddress"] }),
                filterAll: true
            },
            {
                Header: 'Kund - postnummer',
                accessor: 'customerzipcode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customerzipcode"] }),
                filterAll: true
            },
            {
                Header: 'Kund - ort',
                accessor: 'customercity',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customercity"] }),
                filterAll: true
            },
            {
                Header: 'Rutt',
                accessor: 'routeName',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["routeName"] }),
                filterAll: true
            },
            {
                Header: 'Sekvens',
                accessor: 'stopNumber',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["stopNumber"] }),
                filterAll: true
            },
            {
                Header: 'Delivery Group',
                accessor: 'deliveryGroup',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["deliveryGroup"] }),
                filterAll: true
            },
            {
                Header: 'Zon',
                accessor: 'zone',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["zone"] }),
                filterAll: true
            },
            {
                Header: 'Order - kollin',
                accessor: 'packageType',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["packageType"] }),
                filterAll: true
            },

            {
                Header: 'Antal kollin',
                accessor: 'numberOfItems',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["numberOfItems"] }),
                filterAll: true
            },
            {
                Header: 'Order - artiklar',
                accessor: 'packageContent',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["packageContent"] }),
                filterAll: true
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true
            },
            {
                Header: 'Totalvikt',
                accessor: 'totalWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["totalWeight"] }),
                filterAll: true,
            },
            {
                Header: 'Vikt torrt',
                accessor: 'dryWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["dryWeight"] }),
                filterAll: true,
            },
            {
                Header: 'Vikt kylt',
                accessor: 'chilledWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["chilledWeight"] }),
                filterAll: true,
            },
            {
                Header: 'Vikt fryst',
                accessor: 'frozenWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["frozenWeight"] }),
                filterAll: true,
            }
        ]
        var divStyle = {
            display: this.state.isLoading ? 'block' : 'none'
        };
        const { isAuthenticated } = this.props.auth;

        const exportdisabled = !(this.state.rows != null && this.state.rows.length > 0);
        const searchdisabled = this.state.formattedValue == null || (this.state.toggleValue == [] || this.state.toggleValue == null || this.state.toggleValue.length == 0);

        const filename = 'Inleveranslist_' + this.state.formattedValue + '.xlsx';

        return (
            <div className="container">
                {
                    isAuthenticated() && (
                        <div>
                            <div className="cover" style={divStyle}>
                                <div className="covercontent">
                                    <SyncLoader
                                        color={'#174b5F'}
                                        loading={this.state.isLoading}
                                    />
                                </div>
                            </div>

                            <h3>Planerade ordrar</h3>
                            <div className="summarySearch">
                                <div className="dropdownSearch">

                                    <div className="datePicker">Datum: <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleDateChange.bind(this)}
                                        monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                                        dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                                    </div>
                                    <br />
                                    <br />
                                    <Select placeholder="Välj kund/butik"
                                        name="ddSelectMerchant" isMulti
                                        onChange={this.handleChange}
                                        options={clients}

                                        className="basic-multi-select"
                                    />


                                </div>



                                <div className="buttonSearch">
                                    <Button
                                        bsStyle="primary"
                                        className="btn-margin" disabled={searchdisabled}
                                        onClick={this.searchClick.bind(this)}>Sök</Button>
                                </div>
                            </div>
                            <br />

                            <Workbook filename="plannedorders.xlsx" element={<button disabled={exportdisabled} className="btn btn-primary">Exportera till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.rows} name="Planned orders">
                                    <Workbook.Column label="Merchant" value="merchant" />
                                    <Workbook.Column label="Order ID" value="external_ref" />
                                    <Workbook.Column label="Delivery Date" value="deliveryDate" />
                                    <Workbook.Column label="Time Window" value="timewindow" />
                                    <Workbook.Column label="Start Time Window" value="start" />
                                    <Workbook.Column label="End Time Window" value="end" />
                                    <Workbook.Column label="Promised Time" value="promisedTime" />
                                    <Workbook.Column label="Customer name" value="customername" />
                                    <Workbook.Column label="Customer Address" value="customeraddress" />
                                    <Workbook.Column label="Customer Zipcode" value="customerzipcode" />
                                    <Workbook.Column label="Customer City" value="customercity" />
                                    <Workbook.Column label="Route Name" value="routeName" />
                                    <Workbook.Column label="Stop Number" value="stopNumber" />
                                    <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                                    <Workbook.Column label="Zone" value="zone" />
                                    <Workbook.Column label="Package Type" value="packageType" />
                                    <Workbook.Column label="Number of Packages" value="numberOfItems" />
                                    <Workbook.Column label="Package Content" value="packageContent" />
                                    <Workbook.Column label="latitude" value="latitude" />
                                    <Workbook.Column label="longitude" value="longitude" />
                                    <Workbook.Column label="Total Weight" value="totalWeight" />
                                    <Workbook.Column label="Dry Weight" value="dryWeight" />
                                    <Workbook.Column label="Chilled Weight" value="chilledWeight" />
                                    <Workbook.Column label="Frozen Weight" value="frozenWeight" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br /><br />
                            <Workbook filename={filename} element={<button disabled={exportdisabled} className="btn btn-primary">Inleveranslista till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.plocklista} name="Inleveranslista">
                                    <Workbook.Column label="Antal" value="count" />
                                    <Workbook.Column label="Artikel" value="name" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br /><br />
                            <Workbook filename="Ruttlist_.xlsx" element={<button disabled={exportdisabled} className="btn btn-primary">Ruttlista till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.rows} name="Blad1">
                                    <Workbook.Column label="Merchant" value="merchant" />
                                    <Workbook.Column label="Order ID" value="external_ref" />
                                    <Workbook.Column label="Customer name" value="customername" />
                                    <Workbook.Column label="Route Name" value="routeName" />
                                    <Workbook.Column label="Stop Number" value="stopNumber" />
                                    <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                                    <Workbook.Column label="Package Type" value="packageType" />
                                    <Workbook.Column label="Number of Packages" value="numberOfItems" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br /><br />
                            <Workbook filename="Packagelist_.xlsx" element={<button disabled={exportdisabled} className="btn btn-primary">Packlista till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.rows} name="Blad1">
                                    <Workbook.Column label="Merchant" value="merchant" />
                                    <Workbook.Column label="Order ID" value="external_ref" />
                                    <Workbook.Column label="Customer name" value="customername" />
                                    <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                                    <Workbook.Column label="Number of Packages" value="numberOfItems" />
                                    <Workbook.Column label="Package Content" value="packageContent" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br /><br />
                            <Workbook filename="totallist.xlsx" element={<button disabled={exportdisabled} className="btn btn-primary">Totallista till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.rows} name="Planned orders">
                                    <Workbook.Column label="Merchant" value="merchant" />
                                    <Workbook.Column label="Order ID" value="external_ref" />
                                    <Workbook.Column label="Start Time Window" value="start" />
                                    <Workbook.Column label="End Time Window" value="end" />
                                    <Workbook.Column label="Delivery Date" value="deliveryDate" />
                                    <Workbook.Column label="Time Window" value="timewindow" />
                                    <Workbook.Column label="Promised Time" value="promisedTime" />
                                    <Workbook.Column label="Customer name" value="customername" />
                                    <Workbook.Column label="Customer Address" value="customeraddress" />
                                    <Workbook.Column label="Customer Zipcode" value="customerzipcode" />
                                    <Workbook.Column label="Customer City" value="customercity" />
                                    <Workbook.Column label="Customer Entrycode" value="entrycode" />
                                    <Workbook.Column label="Customer Email" value="email" />
                                    <Workbook.Column label="Customer Phone" value="mobilePhone" />
                                    <Workbook.Column label="Delivery Instructions" value="deliveryInstructions" />
                                    <Workbook.Column label="Route Name" value="routeName" />
                                    <Workbook.Column label="Stop Number" value="stopNumber" />
                                    <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                                    <Workbook.Column label="Zone" value="zone" />
                                    <Workbook.Column label="Package Type" value="packageType" />
                                    <Workbook.Column label="Number of Packages" value="numberOfItems" />
                                    <Workbook.Column label="Package Content" value="packageContent" />
                                    <Workbook.Column label="Total Weight" value="totalWeight" />
                                    <Workbook.Column label="Dry Weight" value="dryWeight" />
                                    <Workbook.Column label="Chilled Weight" value="chilledWeight" />
                                    <Workbook.Column label="Frozen Weight" value="frozenWeight" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br /><br />
                            <ReactTable

                                filterable
                                data={this.state.rows}
                                defaultSorted={[{
                                    id: 'routeName',
                                    desc: false,
                                },
                                {
                                    id: 'stopNumber',
                                    desc: false,
                                }]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={columns}

                                pageSize={this.state.pageSize}
                                defaultPageSize={50} >
                                {(state, makeTable, instance) => {
                                    let recordsInfoText = "";

                                    const { filtered, pageRows, pageSize, sortedData, page } = state;

                                    if (sortedData && sortedData.length > 0) {
                                        let isFiltered = filtered.length > 0;

                                        let totalRecords = sortedData.length;

                                        let recordsCountFrom = page * pageSize + 1;

                                        let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                                        if (isFiltered)
                                            recordsInfoText = `${totalRecords} filtrerade rader`;
                                        else
                                            recordsInfoText = `${totalRecords} rader`;
                                    } else recordsInfoText = "Inga träffar";

                                    return (
                                        <div className="main-grid">
                                            <div className="above-table text-left">
                                                <div className="col-sm-12">
                                                    <span className="records-info">{recordsInfoText}</span>
                                                </div>
                                            </div>

                                            {makeTable()}
                                        </div>
                                    );
                                }}
                            </ReactTable>

                        </div>
                    )
                }
                {
                    !isAuthenticated() && (
                        <h4>
                            You're not logged in! Click {' '}
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={this.login.bind(this)}
                            >
                                Login
                </a>
                            {' '}to continue.
                        </h4>
                    )
                }
            </div>

        );
    }
}

export default Planned;