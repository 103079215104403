import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import _ from "lodash";
import moment from 'moment';
import { Button, Grid, Row, Col } from "react-bootstrap";
import { SyncLoader } from 'react-spinners';
import matchSorter from 'match-sorter';
import Workbook from 'react-excel-workbook';

class DailyRoutes extends Component {
    constructor(props) {
        super(props);
    }

    intervalID = 0;

    componentWillMount() {
        this.setState({
            group: '',
            rows: [], originalrows: [],
            currentDate: moment(),
            yesterday: moment().add(-1, 'days'),
            tomorrow: moment().add(1, 'days'),
            isLoading: true
        });
    }
    componentDidMount() {
        var group = localStorage.getItem('access_group');
        this.setState({
            group
        });
        this.getRoutes(moment(), group);

        this.intervalID = setInterval(this.getRoutes.bind(this, moment(), group), 60000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    next() {
        var date = moment(this.state.currentDate).add(1, 'days');
        this.setState({
            currentDate: moment(this.state.currentDate).add(1, 'days'),
            yesterday: moment(this.state.yesterday).add(1, 'days'),
            tomorrow: moment(this.state.tomorrow).add(1, 'days'),
            isLoading: true
        });

        this.getRoutes(date, this.state.group);

        clearInterval(this.intervalID);

        this.intervalID = setInterval(this.getRoutes.bind(this, date, this.state.group), 60000);
    }

    previous() {
        var date = moment(this.state.currentDate).add(-1, 'days');
        this.setState({
            currentDate: moment(this.state.currentDate).add(-1, 'days'),
            yesterday: moment(this.state.yesterday).add(-1, 'days'),
            tomorrow: moment(this.state.tomorrow).add(-1, 'days'),
            isLoading: true
        });

        this.getRoutes(date, this.state.group);

        clearInterval(this.intervalID);

        this.intervalID = setInterval(this.getRoutes.bind(this, date, this.state.group), 60000);
    }

    getRoutes(date, group) {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var url = process.env.REACT_APP_GORDON_API_URL + '/route/dailyroutes?merchant=' + group + "&date=" + moment(date).format("YYYY-MM-DD");
            fetch(url, {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {

                    var rows = [];

                    for (var i = 0; i < responseJson.length; i++) {
                        if (responseJson[i].numberOfStops > 0) {

                            if (responseJson[i].startTime == null && moment(responseJson[i].promisedStartTime, "HH:mm").diff(moment(moment(), "HH:mm"), 'minutes') > 0 &&
                                moment(responseJson[i].promisedStartTime) < moment()) {
                                responseJson[i].missingStart = true;
                            }

                            if (responseJson[i].endTime == null && moment(responseJson[i].promisedEndTime, "HH:mm").diff(moment(moment(), "HH:mm"), 'minutes') > 0 &&
                                moment(responseJson[i].promisedEndTime) < moment()) {
                                responseJson[i].missingEnd = true;
                            }

                            responseJson[i].promisedStartTime = moment(responseJson[i].promisedStartTime).format("HH:mm");
                            responseJson[i].promisedEndTime = moment(responseJson[i].promisedEndTime).format("HH:mm");
                            responseJson[i].startTime = responseJson[i].startTime != null ? moment(responseJson[i].startTime).format("HH:mm") : "";
                            responseJson[i].endTime = responseJson[i].endTime != null ? moment(responseJson[i].endTime).format("HH:mm") : "";


                            responseJson[i].deliveryDate = moment(responseJson[i].deliveryDate).format("YYYY-MM-DD");
                            responseJson[i].distance = Math.round(responseJson[i].distanceinmeter / 1000);
                            if (responseJson[i].status == "Assigned") {
                                responseJson[i].status = "Uppladdat till Bringg " + moment(responseJson[i].updated_at).format("YYYY-MM-DD HH:mm");

                            }
                            else if (responseJson[i].status == "Uploading") {
                                responseJson[i].status = "Laddar upp till Bringg...";
                            }
                            else if (responseJson[i].status == "Done") {
                                responseJson[i].status = "Avslutad";
                            }
                            else if (responseJson[i].status == "Started") {
                                responseJson[i].status = "Startad";
                            }
                            else {
                                responseJson[i].status = "Ej uppladdad";
                            }

                            rows.push(responseJson[i]);
                        }
                    }

                    this.state.rows = rows;
                    this.setState({ rows: rows });
                    this.state.originalrows = rows;
                    this.setState({ originalrows: rows });
                    this.setState({
                        isLoading: false,
                        pageSize: rows.length
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            console.error("401 Unauthorized");
            this.setState({
                isLoading: false
            });
        }
    }
    render() {

        const columns = [
            {
                Header: 'Butik',
                accessor: 'merchant',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["merchant"] }),
                filterAll: true
            },
            {
                Header: 'Rutt',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
                width: 200,
                Cell: row => (
                    <a href={'/routedorders?routename=' + row.value}>
                        {row.value}
                    </a>
                )
            },
            {
                Header: 'Planerad starttid',
                accessor: 'promisedStartTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["promisedStartTime"] }),
                filterAll: true,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            color: row.original.missingStart ? '#ff0000' : '#000000'
                        }}
                    >
                        {row.value}

                    </div>
                )
            },
            {
                Header: 'Faktisk starttid',
                accessor: 'startTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["startTime"] }),
                filterAll: true
                ,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            color: moment(row.value, "HH:mm").diff(moment(row.original.promisedStartTime, "HH:mm"), 'minutes') <= 0 ? '#228B22' : '#ff0000'
                        }}
                    >
                        {row.value}

                    </div>
                )
            },
            {
                Header: 'Planerad sluttid',
                accessor: 'promisedEndTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["promisedEndTime"] }),
                filterAll: true,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            color: row.original.missingEnd ? '#ff0000' : '#000000'
                        }}
                    >
                        {row.value}

                    </div>
                )
            },
            {
                Header: 'Faktisk sluttid',
                accessor: 'endTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["endTime"] }),
                filterAll: true
            },
            {
                Header: 'Kunder',
                accessor: 'numberOfStops',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["numberOfStops"] }),
                filterAll: true,
                width: 100
            }, {
                Header: 'Körsträcka km',
                accessor: 'distance',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["distance"] }),
                filterAll: true,
                width: 100
            }, {
                Header: 'Gordoneer',
                accessor: 'driver',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["driver"] }),
                filterAll: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#dadada',
                            borderRadius: '2px'
                        }}
                    >

                        {
                            row != null && row.original != null && row.original.status != null && (row.original.status.startsWith('Uppladdat till Bringg') || row.original.status.startsWith('Startad') || row.original.status.startsWith('Avslutad')) && (
                                <div
                                    style={{
                                        width: `${row.original.percentage}%`,
                                        height: '100%',
                                        backgroundColor: row.original.percentage == 0 && !row.original.status.startsWith('Startad') ? '#808080' : row.original.percentage == 0 && row.original.status.startsWith('Startad') ? '#0000ff'
                                            : row.original.percentage > 99 ? '#228B22'
                                                : row.original.timediff > 29 || row.original.timediff < -29 ? '#ff0000' :
                                                    row.original.timediff > 14 || row.original.timediff < -14 ? '#FFA500' : '#0000ff',
                                        borderRadius: '2px',
                                        transition: 'all .2s ease-out'
                                    }}
                                >
                                    <span style={{
                                        color: '#ffffff',
                                        paddingLeft: '10px'

                                    }}>
                                        {row.original.percentage}% ({row.original.done} / ({row.original.numberOfStops})
                    </span>
                                </div>
                            )}
                        {
                            row != null && row.original != null && row.original.status != null &&
                            !row.original.status.startsWith('Uppladdat till Bringg') && !row.original.status.startsWith('Avslutad') && !row.original.status.startsWith('Startad') && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '2px'
                                    }}
                                >
                                    <span>{row.value}</span>
                                </div>
                            )}
                    </div>
                )
            },
            {
                Header: 'Sen/tidig',
                accessor: 'timediff',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["timediff"] }),
                filterAll: true,
                Cell: row => (
                    <span>{row.value > 0 ? row.value + " min tidig på rutten" : (row.value * -1) + " min sen på rutten"} </span>
                )
            }
        ]
        var divStyle = {
            display: this.state.isLoading ? 'block' : 'none'
        };
        return (
            <div className="container">

                <div>
                    <div className="cover" style={divStyle}>
                        <div className="covercontent">
                            <SyncLoader
                                color={'#174b5F'}
                                loading={this.state.isLoading}
                            />
                        </div>
                    </div>
                    <div>
                        <br />
                        <Grid>
                            <Row className="show-grid">
                            <Col xs={12} md={8}>
                                    <Button
                                        bsStyle="primary"
                                        className="btn-margin eventbtn"
                                        onClick={this.previous.bind(this)}
                                    >
                                        {'<<'} {this.state.yesterday.format("YYYY-MM-DD")}
                                    </Button>
                                    {this.state.currentDate.format("YYYY-MM-DD")}
                                    <Button
                                        bsStyle="primary"
                                        className="btn-margin eventbtn"
                                        onClick={this.next.bind(this)}
                                    >
                                        {this.state.tomorrow.format("YYYY-MM-DD")} {'>>'}
                                    </Button>
                                </Col>
                                <Col xs={6} md={4}>
                                    <Workbook filename={this.state.currentDate.format("YYYY-MM-DD") + "-routes.xlsx"} element={<button className="btn btn-primary pull-right">Exportera till Excel</button>}>
                                        <Workbook.Sheet data={() => this.state.rows} name="Routes">
                                            <Workbook.Column label="Kund" value="merchant" />
                                            <Workbook.Column label="Route" value="name" />
                                            <Workbook.Column label="Planerad starttid" value="promisedStartTime" />
                                            <Workbook.Column label="Faktisk starttid" value="startTime" />
                                            <Workbook.Column label="Planerad sluttid" value="promisedEndTime" />
                                            <Workbook.Column label="Faktisk sluttid" value="endTime" />
                                            <Workbook.Column label="Antal stopp" value="numberOfStops" />
                                            <Workbook.Column label="Antal stopp - ZON 1" value="numberOfStopsZone1" />
                                            <Workbook.Column label="Antal stopp - ZON 2" value="numberOfStopsZone2" />
                                            <Workbook.Column label="Antal stopp - ZON 3" value="numberOfStopsZone3" />
                                            <Workbook.Column label="Antal stopp - ZON 4" value="numberOfStopsZone4" />
                                            <Workbook.Column label="Körsträcka km" value="distance" />
                                            <Workbook.Column label="Förare" value="driver" />
                                            <Workbook.Column label="Status" value="status" />
                                            <Workbook.Column label="Sen/tidig på rutten (minuter)" value="timediff" />
                                        </Workbook.Sheet>
                                    </Workbook>
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                    <br />
                    <ReactTable
                        filterable
                        data={this.state.rows}
                        defaultSorted={[{
                            id: 'promisedStartTime',
                            desc: false,
                        }]}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={columns}
                        showPagination={false}
                        pageSize={this.state.pageSize}
                        defaultPageSize={50} />

                </div>
            </div>
        );
    }
}

export default DailyRoutes;

