import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

var data;

class GordoneerVehicleLog extends Component {

    componentWillMount() {
        this.setState({ data: {} });
        return { data: data };
    }

    componentDidMount() {

        var user = localStorage.getItem('user');
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/metroteclog/getlog/' + user, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((dataJson) => {

                    data = dataJson;
                    for (let i = 0; i < data.length; i++) {
                        var d = data[i];
                        if (data[i].action == 'GENERATE_PIN') {
                            data[i].action = "Hämtat pinkod";
                        } else if (data[i].action == 'LOCK') {
                            data[i].action = "Låst fordon";
                        } else if (data[i].action == 'UNLOCK') {
                            data[i].action = "Låste upp fordon";
                        }
                        data[i].created_at = new Date(data[i].created_at).toLocaleString();
                    }
                    this.state.data = data;
                    this.setState({ data: data });
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }
    vehicleFormatter(cell, row) {
        const link = 'fleetinfo?regnr=' + cell;
        return ' <a href="' + link + '">' + cell + '</a>';
    }

    render() {

        return (
            <div className="container">

                <div>
                    <div >
                        <BootstrapTable data={data} striped={true} hover={true} className="vehiclelog">
                            <TableHeaderColumn width='220' dataField='created_at' isKey>Tidpunkt</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.vehicleFormatter} dataField='registration'>Registreringsnummer</TableHeaderColumn>
                            <TableHeaderColumn dataField='action'>Action</TableHeaderColumn>
                            <TableHeaderColumn dataField='comment' columnTitle>Kommentar</TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                </div>

            </div>

        );
    }
}

export default GordoneerVehicleLog;