import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

var data;

class PointDrivers extends Component {

    componentWillMount() {

        this.setState({ data: {} });
        return { data: data };
    }

    componentDidMount() {

        this.getList();
    }

    getList() {

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/getpointcomplaints/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((dataJson) => {
                    // var dataJ = JSON.parse(dataJson);
                 
                    data = dataJson;

                    const sorter = (a, b) => {
                        if (a.count < b.count) return 1;
                        if (a.count > b.count) return -1;
                        return 0;
                    };

                    data = data.sort(sorter);
                    data = data.slice(0,12);
                    this.state.data = data;
                    this.setState({ data: data });
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    gordoneerFormatter(cell, row) {
        const link = 'gordoneer?name=' + cell;
        return ' <a href="' + link + '">' + cell + '</a>';
    }

    render() {

        return (
            <div className="container">
                <div>
                    <h4>Point System</h4>
                    <div >
                        <BootstrapTable data={data} striped={true} hover={true}>
                            <TableHeaderColumn width='180' dataField='_id' dataFormat={this.gordoneerFormatter} isKey>Gordoneer</TableHeaderColumn>
                            <TableHeaderColumn dataField='count'>Points</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>

            </div>

        );
    }
}

export default PointDrivers;