import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Profile extends Component {
    componentWillMount() {
        this.setState({ profile: {} });
        const { userProfile, getProfile } = this.props.auth;
        if (!userProfile) {
            getProfile((err, profile) => {
                this.setState({ profile });
            });
        } else {
            this.setState({ profile: userProfile });
        }
    }
    render() {
        const { profile } = this.state;
        return (
            <span>
                <Link className="fa fa-fw fa-user profileName" to="/myprofile"></Link>
                <span className="profileName">{profile.name}</span>
            </span>
        );
    }
}

export default Profile;
