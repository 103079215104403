import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';

import { Button, MenuItem, DropdownButton, Panel, ToggleButton, ToggleButtonGroup, ButtonToolbar, Modal, Form, FormControl } from 'react-bootstrap';
var fordon;
var out;

class FleetAdmin extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    this.setState({
      selectedRegnr: ''
    });

    this.setState({
      selectedOut: ''
    });
    this.setState({
      out: []
    });
    this.setState({ fordon: [] });
    return { fordon: fordon, out: out };
  }
  componentDidMount() {

    var out = this.state.out;
    var user = localStorage.getItem('user');
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/vehicle/full', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          fordon = dataJson;

          out.push(['OUT1ON']);
          out.push(['OUT2ON']);
          out.push(['OUT3ON']);
          out.push(['OUT4ON']);
          out.push(['OUT4OFF']);

          this.state.out = out;
          this.setState({ out: out });

          this.state.fordon = fordon;
          this.setState({ fordon: fordon });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  onRegnrSelect(f) {
    this.setState({
      selectedRegnr: f.registration
    });
  }

  onOutSelect(o) {
    this.setState({
      selectedOut: o
    });
  }

  send() {
    var access_token = localStorage.getItem('access_token');

    if (access_token != null) {
      var url = process.env.REACT_APP_GORDON_API_URL + '/metroteclog/test/' + this.state.selectedRegnr + '/' + this.state.selectedOut;
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var result = responseJson;
          alert(result);
        })
        .catch((error) => {
          console.error(error);

          alert(error);
        });
    }
  }

  render() {

    const { isAuthenticated } = this.props.auth;
    var { fordon, out } = this.state;

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Fordon</h3>
              <div>
                <p>Regnr: *</p>
                <DropdownButton
                  bsStyle="default"
                  title="Regnr"
                  key="1"
                  id="ddRegnr"
                >
                  {fordon.map((f, i) =>
                    <MenuItem key={i} onSelect={() => this.onRegnrSelect(f)} >{f.registration}</MenuItem>)}
                </DropdownButton>
                <p>{this.state.selectedRegnr}</p>
              </div>
              <br />
              <div>
                <p>OUTPUT: *</p>
                <DropdownButton
                  bsStyle="default"
                  title="Out"
                  key="1"
                  id="ddOut"
                >
                  {out.map((o, j) =>
                    <MenuItem key={j} onSelect={() => this.onOutSelect(o)} >{o}</MenuItem>)}
                </DropdownButton>
                <p>{this.state.selectedOut}</p>
              </div>
              <div>
                <Button
                  bsStyle="primary"
                  className="btn-margin btn-padding"
                  onClick={this.send.bind(this)}>Skicka</Button>
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default FleetAdmin;

