import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import _ from "lodash";
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import PropTypes from "prop-types";

class DailyStats extends Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context);
  }

  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    this.setState({
      dateRange: {
        selection: {
          startDate: addDays(new Date(), -7),
          endDate: addDays(new Date(), 0),
          key: 'selection',
        },
      }
    });
    this.setState({ data: [] });
    this.setState({ isLoading: false });
    this.setState({ originalrows: [] });
  }
  componentDidMount() {
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      this.setState({ isLoading: true });
      fetch(process.env.REACT_APP_GORDON_API_URL + '/order/dailystats', {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var data = dataJson;
          for (let i = 0; i < data.length; i++) {
            data[i].link = '/delivery?merchantname=' + data[i].kund + '&date=' + data[i].datum;
          };

          this.setState({ data: data });
          this.setState({ isLoading: false });


        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }


  getStatsClick(row) {

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + "/client/name?name=" + row.row.kund, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {
          var client = dataJson;
          this.context.router.history.push('/delivery?merchant=' + client.external_ref + '&date=' + row.row.datum);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }
  render() {
    return (
      <div className="container">
        <div>
          <ReactTable
            noDataText="Oh Noes!"
            data={this.state.data}
            columns={[

              {
                Header: 'Datum',
                accessor: 'datum'
              },
              {
                Header: 'Butik',
                accessor: 'kund',
              },
              {
                Header: 'Antal',
                accessor: 'antal'
              },
              {
                Header: 'Leveransprecision',
                accessor: 'precision',
                Cell: row => (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#dadada',
                      borderRadius: '2px'
                    }}
                  >

                    <div
                      style={{
                        width: `${row.value}%`,
                        height: '100%',
                        backgroundColor: row.value > 90 ? '#228B22'
                          : row.value > 70 ? '#FFA500'
                            : '#fe0400',
                        borderRadius: '2px',
                        transition: 'all .2s ease-out'
                      }}
                    >
                      <span style={{
                        color: '#ffffff',
                        paddingLeft: '10px'

                      }}>
                        {row.value}%
                  </span>
                    </div>
                  </div>
                )
              }
              ,
              {
                Header: 'Mer info...',
                accessor: 'link',
                maxWidth: 100,
                className: "center",
                Cell: row => (
                  <Button bsStyle="primary"
                    className="btn-margin detailsbtn"
                    onClick={this.getStatsClick.bind(this, row)}>Detaljer...</Button>
                )
              }
            ]}
            defaultPageSize={16}
            filterable={true}
            defaultSorted={[{
              id: 'precision',
              desc: false,
            }]}
            className="-striped -highlight"
          />
        </div>

      </div>

    );
  }
}

export default DailyStats;

