import React, { Component } from "react";
import DatePicker from "react-16-bootstrap-date-picker";
import _ from "lodash";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { SyncLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";

class Documents extends Component {
  constructor(props) {
    super(props);
  }
  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    this.setState({
      isLoading: false
    });
    this.setState({ selectedDeliveryGroup: [], selectedDeliveryGroupName: null });
    this.setState({ formattedValue: null });
    this.setState({ value: "" });
  }
  componentDidMount() {

    const { isAuthenticated } = this.props.auth;

    if (isAuthenticated()) {
      const { getProfile } = this.props.auth;
      getProfile((err, profile) => {
        this.setState({
          profile
        });
      });
    }
  }

  download(path, name, type, size) {
    this.setState({
      isLoading: true
    });
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(
        process.env.REACT_APP_GORDON_API_URL +
        "/" +
        path +
        "/gdp?datum=" +
        this.state.formattedValue +
        "&deliveryGroup=" +
        this.state.selectedDeliveryGroup +
        (size && size.length > 0 ? "&description=" + size : ''),
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + access_token
          },
          responseType: "blob"
        }
      )
        .then(response => response.blob())
        .then(blob => {
          console.log(this.state.profile)
          if (blob && blob.type === 'application/pdf') {
            FileSaver.saveAs(
              blob,
              type +
              " " +
              name +
              " " +
              this.state.selectedDeliveryGroupName +
              " " +
              this.state.formattedValue +
              ".pdf"
            );
          }
          else {
            // alert("Etiketter skickades via mail...");
          }
          this.setState({
            isLoading: false
          });
        })
        .catch(error => {
          console.error(error);
          this.setState({
            isLoading: false
          });
        });
    }
  }

  handleDateChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });
  }
  handleDeliveryGroupChange = selectedOption => {
    if (selectedOption != null) {
      this.setState({
        selectedDeliveryGroup: selectedOption.value,
        selectedDeliveryGroupName: selectedOption.label,
      });
    } else {
      this.setState({
        selectedDeliveryGroup: null,
        selectedDeliveryGroupName: null
      });
    }
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    var divStyle = {
      display: this.state.isLoading ? "block" : "none"
    };
    var deliveryGroups = [
      { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Tumba" },
      { value: "61ee68c02bf9b60bbcbe7d63", label: "Gordon Stockholm Årsta" },
      { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Västberga" },
      { value: "648064d45a639704741f519e", label: "Gordon Stockholm Tumba Temp" },
      { value: "5efcabc203fd75517c04fb39", label: "Gordon Göteborg" },
      { value: "5efcabb103fd75517c04fb38", label: "Gordon Malmö" },
      { value: '5efcac8903fd75517c04fb3b', label: 'Gordon Linköping' },
      { value: '5efcacf603fd75517c04fb3e', label: 'Gordon København, DK' },
      { value: '5efcae2d03fd75517c04fb42', label: 'Gordon Aalborg, DK' },
      { value: '5efcacdb03fd75517c04fb3d', label: 'Gordon Fredericia, DK' },
      { value: '5efcad0703fd75517c04fb3f', label: 'Gordon Århus, DK' },
      { value: '5efcacc303fd75517c04fb3c', label: 'Gordon Eskilstuna' },
      { value: '602b8e0464849a0bb91dbec5', label: 'Gordon Uppsala' },
      { value: '61695c442d8ad20f37a40179', label: 'Gordon Karlstad' },
      { value: "5f71b3f408755a0c6592c78a", label: "Gordon Halmstad" },
      { value: "5f71b3d9d4ecbe0c79a0d28f", label: "Gordon Jönköping" },
      { value: "5f71b3b708755a0c6592c782", label: "Gordon Växjö" },

      { value: "5efcac3303fd75517c04fb3a", label: "Gordon Ljungby" },
      { value: "62a08775ce90de0beb76b28c", label: "Gordon Karlshamn" },
      { value: "630dd5385c0c3b0c06292014", label: "Gordon hissing kärra" },
      { value: "641a0370bf36d430617eaab5", label: "Gordon Gävle" },
      { value: "633aa933d74ce70b8d0e9a6a", label: "Gordon Sundsvall" },
      { value: "613f4684c745ba0ba4fde043", label: "Gordon Oslo" },
      { value: "64365f4f70c5a7e6c2908c08", label: "Gordon Oslo B2B" },
      { value: "62b0554e6b771d0be69a4578", label: "Gordon Bergen" },
      { value: "6474998bddc77898451775cf", label: "Gordon Bergen B2B" }
    ];
    const exportdisabled = !(
      this.state.selectedDeliveryGroup != null &&
      this.state.formattedValue != null
    );

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <div className="cover" style={divStyle}>
              <div className="covercontent">
                <SyncLoader color={"#174b5F"} loading={this.state.isLoading} />
              </div>
            </div>

            <h3>Plocklistor, etiketter m.m.</h3>
            <div className="dropdownSearch">

              <div className="datePicker">
                Datum:{" "}
                <DatePicker
                  autoFocus={false}
                  showTodayButton={true}
                  todayButtonLabel="Idag"
                  id="example-datepicker"
                  value={this.state.value}
                  dateFormat="YYYY-MM-DD"
                  weekStartsOn={1}
                  onChange={this.handleDateChange.bind(this)}
                  monthLabels={[
                    "Januari",
                    "Februari",
                    "Mars",
                    "April",
                    "Maj",
                    "Juni",
                    "Juli",
                    "Augusti",
                    "September",
                    "Oktober",
                    "November",
                    "December"
                  ]}
                  dayLabels={["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"]}
                />
                <br />
              </div>
              <Select
                placeholder="Välj Delivery Group"
                name="ddSelectDeliveryGroup"
                onChange={this.handleDeliveryGroupChange}
                options={deliveryGroups}
              />
              <br />

            </div>

            <br />
            

            <h4>Smakbox</h4>
            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.download.bind(
                this,
                "smakboxtotalplocklista",
                "Smakbox",
                "Total plocklista"
              )}
            >
              Total plocklista
            </Button>
            <br />
            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.download.bind(
                this,
                "smakboxplocklista",
                "Smakbox",
                "Plocklista"
              )}
            >
              Plocklista per rutt
            </Button>
            <br />

            <h4>GroGro</h4>
          
            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.download.bind(
                this,
                "reitanplocklista",
                "GroGro",
                "Plocklista"
              )}
            >
              Plocklista per rutt
            </Button>
            <br />
           
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default Documents;
