import React, { useEffect } from 'react';
import { Navbar, Button, Nav, Badge } from "react-bootstrap";
import { notification } from '../notificationHandler';

import './header.css'



class Header extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        }

        this.updateNotes = this.updateNotes.bind(this);
    }


    updateNotes(notes) {
        this.setState({ ...this.state, notifications: notes });
    }



    componentDidMount() {
        notification.on('notificationUpdate', this.updateNotes)

        let notifications = notification.getNotifications()
        this.setState({ ...this.state, notifications });

    };

    componentWillUnmount() {
        notification.removeListener('notificationUpdate', this.updateNotes)
    }



    render() {
        let { notifications } = this.state;
        return (
            <Navbar fluid>

                <Navbar.Header>
                    <Navbar.Brand>
                        <a href='/home'><img style={{ height: "50px" }} src='/images/gordon_google_logo.png' alt='Gordon Delivery' /></a>
                    </Navbar.Brand>


                    {notifications.length > 0 && this.props.isAuthenticated() && (

                        <div className="badge notify-icon" style={{ marginTop: "15px", marginBottom: "8px", backgroundColor: "#f54040", userSelect: "none" }} onClick={() => { this.props.history.push('/notification') }}>
                            <i className="fas fa-bell" style={{ fontSize: "20px" }}></i>
                            <span style={{ position: "relative", bottom: "4px", marginLeft: "5px", fontSize: "14px" }}>{notifications.length}</span>
                        </div>

                    )}
                    <Button
                        bsStyle="primary"
                        className="btn-margin navbar-btn pull-right"
                        onClick={this.props.isAuthenticated() ? this.props.logout : this.props.login}
                    >
                        {this.props.isAuthenticated() ? 'Log out' : 'Login'}
                    </Button>


                </Navbar.Header>
            </Navbar>)
    }
}

export default Header;