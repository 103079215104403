import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Gauge from 'react-svg-gauge';

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class HemkopDjur extends Component {

    componentWillMount() {

        let donutrowshemkop = [];
        donutrowshemkop.push(['Typ', "Procent"]);
        this.setState({ donutrowshemkop: donutrowshemkop });

        this.setState({ summaryhemkop: { Driver: '' } });
        this.setState({ hemkopcolor: '#fe0400' });
        this.setState({summaryhemkop: {LeveransPrecision : 0}});
        return { donutrowshemkop: donutrowshemkop };
    }

    componentDidMount() {
        let donutrowshemkop = this.state.donutrowshemkop;

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var donuturhemkop = 'https://api.gordondelivery.com/api/hemkop/latest/';
            fetch(donuturhemkop, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var summary = JSON.parse(responseJson);
                    donutrowshemkop.push(["Inom 15 min", summary[0].Lyckade]);
                    donutrowshemkop.push(["Inom 30 min", summary[0].Inom1530]);
                    donutrowshemkop.push(["Inom 60 min", summary[0].Inom3060]);
                    donutrowshemkop.push(["Över 60 min", summary[0].MerAn60]);
                    donutrowshemkop.push(["Uteblivna", summary[0].Utebliven]);

                    var hemkopcolor = '#fe0400';
                    if (summary[0].LeveransPrecision > 90) {
                        hemkopcolor = '#228B22';
                    }
                    else if (summary[0].LeveransPrecision > 70) {
                        hemkopcolor = '#FFA500';
                    }

                    this.setState({ hemkopcolor: hemkopcolor });
                    this.state.summaryhemkop = summary[0];
                    this.setState({ summaryhemkop: summary[0] });
                    this.state.hemkopdatum = summary[0].Datum;
                    this.setState({ hemkopdatum: summary[0].Datum });
                    this.state.donutrowshemkop = donutrowshemkop;
                    this.setState({ donutrowshemkop: donutrowshemkop });

                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    _buildLinkHrefHemkop() {
        return '/delivery?merchant=AXHD&date=' + this.state.hemkopdatum;
    }

    render() {

        return (
            <div>
                <div className="dashboardleft">

                    <h5>Hemköp Djurgårdsstaden - {this.state.hemkopdatum}</h5>

<div className="smallcircleDiv">
                        <CircularProgressbar percentage={this.state.summaryhemkop.LeveransPrecision} 
                        text={`${this.state.summaryhemkop.LeveransPrecision}%`}  
                         strokeWidth={5}
                        // You can override styles either by specifying this "styles" prop,
                        // or by overriding the default CSS here:
                        // https://github.com/iqnivek/react-circular-progressbar/blob/master/src/styles.css
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the part that's "complete"
                          path: {
                            // Tweak path color:
                            stroke: this.state.hemkopcolor,
                            // Tweak path to use flat or rounded ends:
                            strokeLinecap: 'butt',
                            // Tweak transition animation:
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                          },
                          // Customize the circle behind the path
                          trail: {
                            // Tweak the trail color:
                            stroke: '#d6d6d6',
                          },
                          // Customize the text
                          text: {
                            // Tweak text color:
                            fill: this.state.hemkopcolor,
                            // Tweak text size:
                            fontSize: '30px',
                          },
                        }}
                      />
                    </div>

                    {/* <Gauge value={this.state.summaryhemkop.LeveransPrecision} width={300} height={150} color={this.state.hemkopcolor} label="" /> */}
                </div>
                {/* <ul>
                        <li>Vi levererade totalt <b>{this.state.summaryhemkop.Antal}</b> ordrar</li>
                        <li><b>{this.state.summaryhemkop.Lyckade}</b> ordrar levererades inom 15 minuter.</li>
                        <li><b>{this.state.summaryhemkop.Utebliven}</b> ordrar avbröts och levererades inte.</li>
                        <li><b>{this.state.summaryhemkop.Inom1530}</b> ordrar levererades inom 30 min.</li>
                        <li><b>{this.state.summaryhemkop.Inom3060}</b> ordrar levererades inom 60 min.</li>
                        <li><b>{this.state.summaryhemkop.MerAn60}</b> ordrar levererades över 60 minuter sent.</li>
                    </ul>

                    <div className="dashboardleft">  <p>   Gruppens totala leveransprecision var <b>{this.state.summaryhemkop.LeveransPrecision}%</b>.</p></div> */}
                <div className="dashboardright">
                    <Button href={this._buildLinkHrefHemkop()}
                        bsStyle="primary"
                        className="btn-margin"
                    >
                        Detaljer...
</Button>



                </div>
                <div className="dashboardtoprightsmall">
                    <p><strong>{this.state.summaryhemkop.Antal} st</strong></p>
                </div>
            </div>
        );
    }
}

export default HemkopDjur;