import EventEmitter from 'events';

class NotificationHandler extends EventEmitter {
    constructor(props) {
        super(props);

        this.auth = null;
        this.notifications = [];
        this.timer = '';

        this.timeoutInterval = 60000;

    }

    init(auth) {
        if (!auth) {
            console.log('notification handler requires auth for signing up on login/logout events')
        }
        this.auth = auth;

        this.auth.on('login', this._login.bind(this));
        this.auth.on('logout', this._logout.bind(this));
        if(this.auth.isAuthenticated()){
            this._fetchNotifications();
        }
    }

  
    getNotifications() {
        return this.notifications;
    }

    start() {
        this._fetchNotifications();
    }

    stop() {
        this._logout();
        this.auth.removeListener('login', this._login.bind(this));
        this.auth.removeListener('logout', this._logout.bind(this));
    }

    signOffNote(note) {
        let access_token = localStorage.getItem('access_token');
        console.log(note)

        fetch(process.env.REACT_APP_GORDON_API_URL + '/notification/remove', {
            method: 'POST',
            body: JSON.stringify(note),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + access_token,
            },
        }).then(() => {
            this.notifications = this.notifications.filter(n => n._id !== note._id);
            this.emit('notificationUpdate', this.notifications);
        }).catch((err) => {
            console.log(err);
        })
    }

   
    _fetchNotifications() {
        if (!this.timer) {
            this.timer = setInterval(() => this._fetchNotifications(), this.timeoutInterval)
        }   

        let access_token = localStorage.getItem('access_token');

        fetch(process.env.REACT_APP_GORDON_API_URL + '/notification', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + access_token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 'ok') {

                    this.notifications = result.data;
                    this.emit('notificationUpdate', result.data);
               

                }

            })
            .catch((err) => {
                console.log(err);
            });
    }

    _login() {

        console.log('user logged in')
        this._fetchNotifications();
    }

    _logout() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.notifications = [];
        console.log('user logged out')
    }


}


export let notification = new NotificationHandler();