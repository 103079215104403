import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import 'moment/locale/sv';

const queryString = require('query-string');
var data;

class AssignedOrders extends Component {

  constructor() {
    super();
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
  }

  handleDropDownChange(event) {
    this.setState({ selectedstatus: event.target.value });
  }

  login() {
    this.props.auth.login();
  }
  hideModal() {
    this.setState({ showError: false });
  }
  hideModal2() {
    this.setState({ showError2: false });
  }
  componentWillMount() {
    this.setState({ data: {} });
    this.setState({ ismulti: false });
    this.setState({ title: '' });
    this.setState({ merchant: '' });
    this.setState({ showSms: false });
    this.setState({ msg: null });
    this.setState({ customers: [] });
    this.setState({ selected: [] });
    this.setState({
      showError: false,
      showError2: false,
    });
    return { data: data, title: '' };
  }

  cancel() {
    this.setState({
      showSms: false
    });
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.OrderId]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.OrderId)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.OrderId);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  getOrders() {
    var parsed = queryString.parse(this.props.location.search);
    if (parsed.date != null && parsed.user != null) {

      var user = parsed.user;
      var merchant = parsed.merchant;
      var date = parsed.date;

      var title = "Ordrar assignade till " + user + " den " + moment(date).format('YYYY-MM-DD');
      this.state.title = title;
      this.setState({ title: title });
      this.setState({ merchant: merchant });
      moment.locale('sv');

      var access_token = localStorage.getItem('access_token');
      if (access_token != null) {


        var url = process.env.REACT_APP_GORDON_API_URL + '/order/assignedordersbydateanddriver?merchant=' + merchant + '&date=' + date + '&driver=' + user;

        fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
        })
          .then((response) => response.json())
          .then((dataJson) => {

            this.setState({ ismulti: true });
            data = dataJson;
            for (let i = 0; i < data.length; i++) {
              if (data[i].PromisedTime != undefined) {
                data[i].PromisedTime = moment(data[i].PromisedTime).format('YYYY-MM-DD HH:mm:ss');
              }
            }
            this.state.data = data;
            this.setState({ data: data });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error("401 Unauthorized");
      }
    }
  }

  sendSms() {

    var msg = this.state.msg;
    var merchant = this.state.merchant;
    if (msg == null || msg == "") {
      this.setState({ showError2: true });
    }
    else {
      var customers = [];
      for (let i = 0; i < this.state.selected.length; i++) {

        data.forEach(row => {
          if (row.OrderId == this.state.selected[i]) {
            customers.push({ 'name': row.CustomerName, 'phone': row.CustomerPhone })
          }
        });
      }
      var access_token = localStorage.getItem('access_token');
      if (access_token != null) {

        var url = process.env.REACT_APP_GORDON_API_URL + '/order/sms';
        var method = 'POST';

        var body = { 'msg': msg, 'customers': customers, 'merchant': merchant };
        fetch(url, {
          method: method,
          headers: {
            'Accept': 'appl ication/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
          body: JSON.stringify(body)
        })
          .then((response) => {

            this.setState({ showSms: false });
            alert("SMS skickades!");
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error("401 Unauthorized");
      }

    }
  }

  showSmsDialog() {
    if (this.state.selected.length == 0) {
      this.setState({ showError: true });
    }
    else {

      this.setState({ showSms: true });
    }
  }

  componentDidMount() {
    this.getOrders();
  }

  msgChanged(evt) {
    const msg = evt.target.value;
    this.setState({ msg });
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>{this.state.title}</h3>
              <div className="floatRight">

                <Button
                  bsStyle="primary"
                  className="btn-margin btn-padding marginleft"
                  onClick={this.showSmsDialog.bind(this)}>Skicka SMS...</Button>
              </div>
              <br /><br />
              <br /><br />
              <div className="divbreak">
                <BootstrapTable data={data} editable={false} striped={true} hover={true} selectRow={selectRow}  >
                  <TableHeaderColumn width='90' editable={false} dataField='OrderId' isKey>Order ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='CustomerName' editable={false} columnTitle>Kundens namn</TableHeaderColumn>
                  <TableHeaderColumn dataField='Address' editable={false} columnTitle>Kundens adress</TableHeaderColumn>
                  <TableHeaderColumn dataField='CustomerPhone' editable={false} columnTitle>Kundens telefonnummer</TableHeaderColumn>
                  <TableHeaderColumn dataField='PromisedTime' editable={false} columnTitle>Utlovad tid</TableHeaderColumn>
                  <TableHeaderColumn dataField='State' width='200' columnTitle >Status</TableHeaderColumn>
                  <TableHeaderColumn dataField='Status' width='150' editable={false} columnTitle>Leveransstatus</TableHeaderColumn>
                </BootstrapTable>
              </div>

              <Modal
                {...this.props}
                show={this.state.showSms}
                onHide={this.cancel.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Skicka SMS till kunder...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Ange meddelande</h4>
                  <br />
                  <p>Hej KUNDNAMN!</p>
                  <textarea className="message-creator__content" onInput={this.msgChanged.bind(this)} value={this.state.msg} />
                  <p>MVH Gordon Delivery</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.cancel.bind(this)}>Stäng</Button>
                  <Button bsStyle="success" onClick={this.sendSms.bind(this)}>Skicka</Button>
                </Modal.Footer>
              </Modal>

              <Modal
                {...this.props}
                show={this.state.showError}
                onHide={this.hideModal.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Ooops...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Du måste markera minst en order i listan</h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.hideModal.bind(this)}>Stäng</Button>
                </Modal.Footer>
              </Modal>

              <Modal
                {...this.props}
                show={this.state.showError2}
                onHide={this.hideModal2.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Ooops...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Du måste skriva in ett meddelande</h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.hideModal2.bind(this)}>Stäng</Button>
                </Modal.Footer>
              </Modal>

            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }

      </div>

    );
  }
}

export default AssignedOrders;