import React from 'react';
import { bubble as Menu } from 'react-burger-menu';

import './sidebar.css'

class Accordion extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div
                ref={a => this._acc = a}>
                {this.props.children}
            </div>
        )
    }
}

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: '',
            searchString: '',
            searchResults: [],
            loading: false

        }

        this.find = this.find.bind(this);
        this.handleSeach = this.handleSeach.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    find(e) {
        this.eventTraget = e.target;
        let { searchString } = this.state;
        let access_token = localStorage.getItem('access_token');

        this.setState({ ...this.state, loading: true })

        fetch(process.env.REACT_APP_GORDON_API_URL + "/order/searchString", {
            method: 'POST',
            body: JSON.stringify({
                'searchString': searchString,
                'page': 1,
                'pageSize': 20
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + access_token,
            },
        })
            .then((response) => response.json())
            .then((result) => {
                if (typeof result === 'object') {
                    let order = result.orders;
                    this.setState({ ...this.state, loading: false })

                    var evt = new KeyboardEvent('keydown', { isTruste: true, key: 'Escape', keyCode: 27, code: "Escape" });
                    window.dispatchEvent(evt);
                    
                    this.props.history.push({
                        pathname: '/search',
                        state: {
                            result: order,
                            searchString: this.state.searchString,
                            total: result.total,
                            page: result.page
                        }
                    });


                }

            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleSeach(e) {

        let value = e.target.value;
        this.setState({ ...this.state, searchString: value });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.find(e);
        }
    }

    componentDidMount() {
        var group = localStorage.getItem('access_group');
        this.setState({ ...this.state, group });
    }
    _handleClick(e) {
        e.target.parentNode.classList.toggle("active");
    }

    render() {

        return (
            <Menu isOpen={false}>
                <Accordion>

                    <p><a href="/home" className="bm-item-list"><i className="fas fa-home"></i> Home</a></p>
                  
                    <div className="bm-item-list">
                        <div className="search" style={{ borderRadius: "15px", boxShadow: "0", border: "none", padding: "0 0px", color: "#373a47" }}>
                            <input type="text" placeholder="Search order..." style={{ padding: "0 10px", borderRadius: "7.5px 0px 0px 7.5px", border: "none", width: "80%", display: "inline-block" }} onChange={this.handleSeach} onKeyDown={this.handleKeyPress} />
                            <span style={{ width: "20%", display: "inline-block", borderRadius: "0 7.5px 7.5px 0", backgroundColor: "#b8b7ad", textAlign: "center", paddingRight: "5px", borderLeft: "1px solid #373a47", cursor: "pointer" }} onClick={this.find}>
                                {this.state.loading ? <i className="fas fa-sync fa-spin"></i> : <i className="fas fa-search" style={{ marging: "5px" }}></i>}
                            </span>
                        </div>
                    </div>

                    <div>
                        {/* <div className="accor" id="accor1">
                            <div className="head" onClick={this._handleClick} className="bm-item-list"><i className="fas fa-chevron-circle-down"></i> Uppföljning</div>
                            <div className="body">
                                <p><a href="/matkasse" className="bm-item-list"><i className="fas fa-layer-group" /> Matkasseflödet</a></p>
                                <p><a href="/delivery" className="bm-item-list"><i className="fas fa-calendar-check-o" /> Leveranstillfällen</a></p>
                                <p><a href="/followup" className="bm-item-list"><i className="fas fa-clipboard-list" /> Leveransuppföljning</a></p>
                                <p><a href="/monthstats" className="bm-item-list"><i className="fas fa-clipboard-list" /> Månadsuppföljning</a></p>
                                <p><a href="/weeklystats" className="bm-item-list"><i className="fas fa-clipboard-list" /> Veckouppföljning</a></p>
                                <p><a href="/orderimport" className="bm-item-list"><i className="fas fa-file-import" /> Import</a></p>
                            </div>
                        </div> */}
                        <div className="accor" id="accor2">
                            <div className="head" onClick={this._handleClick} className="bm-item-list"><i className="fas fa-chevron-circle-down"></i> Driver</div>
                            <div className="body">
                                <p><a href="/drivers" className="bm-item-list"><i className="fas fa-id-card" ></i> Drivers</a></p>
                                {/* <p><a href="/leaderboard" className="bm-item-list"><i className="fas fa-trophy" ></i> Leaderboards</a></p>
                                <p><a href="/eventlog" className="bm-item-list"><i className="fas fa-info-circle" ></i> Händelselogg</a></p>
                                <p><a href="/latestart" className="bm-item-list"><i className="fas fa-clock-o" ></i> Sen till pass</a></p> */}
                            </div>
                        </div>
                        {/* <div className="accor" id="accor3">
                            <div className="head" onClick={this._handleClick} className="bm-item-list"><i className="fas fa-chevron-circle-down"></i>  Kunder</div>
                            <div className="body">
                                <p><a href="/linasmatkasse" className="bm-item-list"><i className="fas fa-utensils" /> Linas Matkasse</a></p>
                                <p><a href="/axfood" className="bm-item-list"><i className="fas fa-utensils" /> Axfood</a></p>
                                <p><a href="/svartaladan" className="bm-item-list"><i className="fas fa-utensils"></i> Svarta Lådan</a></p>
                                <p> <a href="/wrapsons" className="bm-item-list"><i className="fas fa-utensils" ></i> Wrapsons</a></p>
                                <p><a href="/coop" className="bm-item-list"><i className="fas fa-utensils" ></i> COOP</a></p>
                                <p><a href="/ica" className="bm-item-list"><i className="fas fa-utensils"></i> ICA</a></p>
                                <p><a href="/blomsterlandet"><i className="fas fa-utensils" ></i> Blomsterlandet</a></p>
                                <p><a href="/paradiset"><i className="fas fa-utensils" ></i> Paradiset</a></p>
                                <p><a href="/lundbergs"><i className="fas fa-utensils" ></i> Lundbergs Konditori</a></p>
                            </div>
                        </div> */}
                        <div className="accor" id="accor4">
                            <div className="head" onClick={this._handleClick} className="bm-item-list"><i className="fas fa-chevron-circle-down"></i>  Fordon</div>
                            <div className="body">
                                <p><a href="/fleet" className="bm-item-list"><i className="fas fa-truck" ></i> Fordon</a></p>
                                <p><a href="/fleetmanagement" className="bm-item-list"><i className="fas fa-unlock" ></i> Fordonshantering</a></p>
                                <p> <a href="/fleetlog" className="bm-item-list"><i className="fas fa-truck" /> Fordonslogg</a></p>
                                <p><a href="/templogs" className="bm-item-list"><i className="fas fa-thermometer-empty"></i> Temploggar</a></p>
                            </div>
                        </div>

                        <div className="accor" id="accor5">
                            <div className="head" onClick={this._handleClick} className="bm-item-list"><i className="fas fa-chevron-circle-down"></i>  Ekonomi</div>
                            <div className="body">
                                <p> <a href="/invoicing" className="bm-item-list"><i className="fas fa-credit-card" ></i> Fakturaunderlag</a></p>
                                <p> <a href="/coopinvoicing" className="bm-item-list"><i className="fas fa-credit-card" ></i> Fakturaunderlag COOP</a></p>
                                <p><a href="/ekonomi" className="bm-item-list"><i className="fas fa-money" ></i> Lönsamhet</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="accor" id="accor6">
                        <div className="head" onClick={this._handleClick} className="bm-item-list"><i className="fas fa-chevron-circle-down"></i>  Planering</div>
                        <div className="body">
                            
                                    <div>
                                        <p> <a href="/unplanned" className="bm-item-list"><i className="fas fa-folder-open" /> Oplanerade ordrar</a></p>
                                        <p> <a href="/planned" className="bm-item-list"><i className="fas fa-folder-open" /> Planerade ordrar</a></p>

                                    </div>
                            <p> <a href="/deliveryorders" className="bm-item-list"><i className="fas fa-folder-open" /> Leveransordrar</a></p>
                          
                                    <div>
                                        <p> <a href="/documents" className="bm-item-list"><i className="fas fa-file-excel" /> Documents</a></p>
                                        {/* <p> <a href="/email" className="bm-item-list"><i className="fas fa-at" /> Leveransavisering</a></p> */}
                                        <p> <a href="/result" className="bm-item-list"><i className="fas fa-poll" /> Utfall</a></p>
                                        {/* <p> <a href="/zipcodes" className="bm-item-list"><i className="fas fa-map-pin" /> Postnummer</a></p> */}
                                        <p> <a href="/customernotes" className="bm-item-list"><i className="fas fa-laptop-code" /> Kunduppdateringar</a></p>
                                        {/* <p> <a href="/import" className="bm-item-list"><i className="fas fa-file-import" /> Orderimport</a></p> */}
                                    </div>
                        </div>
                    </div>
                   
                            <p><a href="/sms" className="bm-item-list"><i className="fas fa-comment"></i> Sms</a></p>
                </Accordion>
            </Menu >
        )
    }
};

export default SideBar;