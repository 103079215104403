import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class HighlightGordoneer extends Component {

  componentWillMount() {
    this.setState({ summary: {} });
    this.setState({ photourl: 'images/avatar.png' });
  }

  componentDidMount() {

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      var url1 = 'https://api.gordondelivery.com/api/gordoneer/randomgordoneer';
      fetch(url1, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = JSON.parse(responseJson);
          this.setState({ summary: summary[0] });
          var url2 = process.env.REACT_APP_GORDON_API_URL + '/users/name/' + summary[0].Name;
          fetch(url2, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + access_token,
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson[0].photo != null && responseJson[0].photo.length > 0) {
                this.setState({ photourl: responseJson[0].photo });
              }


            })
            .catch((error) => {
              console.error(error);
            });

        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }

  gordoneerFormatter(cell, row) {
    const link = 'driver?name=' + cell;
    return ' <a href="' + link + '">' + cell + '</a>';
  }

  render() {

    return (
      <div>
        <div className="dashboardcenter">

          <h2><a href={'driver?name=' + this.state.summary.Name}>{this.state.summary.Name}</a></h2>
          <p>En av veckans stjärnor!</p>
          <img src={this.state.photourl} height="200px" />
          <h4>Leveransprecision: {this.state.summary.Precision}%</h4>
          <h4>Antal ordrar: {this.state.summary.Antal} st</h4>
      
        </div>
      </div>

    );
  }
}

export default HighlightGordoneer;