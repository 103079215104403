import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DatePicker from 'react-16-bootstrap-date-picker';
import TimePicker from 'react-bootstrap-time-picker';
import { Button } from 'react-bootstrap';
import { SyncLoader } from 'react-spinners';

var data;
var fordon = [];

class TemplogMetrotec extends Component {
  constructor() {
    super();

    this.handleTimeChangeStart = this.handleTimeChangeStart.bind(this);
    this.handleTimeChangeEnd = this.handleTimeChangeEnd.bind(this);

    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.state = {
      loading: false
    }
    this.state = { starttime: 0 };
    this.state = { endtime: 82800 };
    this.state = { value: '' };

  }
  componentWillMount() {
    this.setState({ loading: false });
    this.setState({ data: {} });
    this.setState({ fordon: [] });
    this.setState({ selectedfordon: '' });
    this.setState({ starttime:0 });
    this.setState({ endtime:82800 });
    this.setState({ formattedValue: null });
    // this.setState({ starttime: 0});
    // this.setState({ endtime: 0});
    return { data: data, fordon: [] };
  }
  handleTimeChangeStart(starttime) {
    console.log(starttime);     // <- prints "3600" if "01:00" is picked
    this.setState({ starttime:starttime });
  }
  handleTimeChangeEnd(endtime) {
    console.log(endtime);     // <- prints "3600" if "01:00" is picked
    this.setState({ endtime:endtime });
  }
  handleDropDownChange(event) {
    this.setState({ selectedfordon: event.target.value });
  }
  getInitialState() {
    var value = new Date().toISOString();
    return {
      value: value
    }
  }
  handleChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z" 
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016" 
    });

  }

  handleToggleChange(toggleValue) {
    this.setState({
      toggleValue: toggleValue
    });
  }

  handleSelect(evt, eventKey) {
    this.setState({ evt: evt });
  }

  componentDidMount() {
    fordon = [];
    var user = localStorage.getItem('user');
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/vehicle', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          fordon = dataJson;
          this.state.fordon = fordon;
          this.setState({ fordon: fordon });
          this.setState({ selectedfordon: fordon[0].regnr });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  getStats() {

    this.setState({ loading: true });
    var s = this.state.starttime;
    var e = this.state.endtime;
    this.state.data = {} ;
    this.setState({ data: {}  });
    const { formattedValue } = this.state;
    var startstring = formattedValue + "T" + (new Date(s * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];;
    var endstring = formattedValue + "T" + (new Date(e * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];;
    var regnr = this.state.selectedfordon;

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      let url = process.env.REACT_APP_GORDON_API_URL + '/metroteclog/gettemplog?regnr=' + regnr + '&start=' + startstring + '&end=' + endstring;
      if(regnr === 'YGE829' || regnr === 'LJJ109' || regnr === 'RJR36H') {
        url = process.env.REACT_APP_GORDON_API_URL + '/metroteclog/getpartnertemplog?regnr=' + regnr + '&start=' + startstring + '&end=' + endstring;
      }
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {
          data = dataJson;
          for (let i = 0; i < data.length; i++) {
            data[i].temp2 = data[i].temp2.toFixed(1);
            data[i].temp3 = data[i].temp3.toFixed(1);
            data[i].temp4 = data[i].temp4.toFixed(1);
            data[i].avg =( (Number(data[i].temp2) + Number(data[i].temp3) + Number(data[i].temp4)) / 3).toFixed(1);
          }
          this.state.data = data;
          this.setState({ data: data });
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  render() {

    const { formattedValue } = this.state;
    const isEnabled = formattedValue != null;

    let bilar = this.state.fordon;
    let optionItems = bilar.map((f) =>
      <option key={f.regnr}>{f.regnr}</option>
    );
    //   let optionItems = bilar.map((f) =>
    //   <MenuItem eventKey={f.regnr}>{f.regnr}</MenuItem>
    // );
    return (
      <div className="container">

        <div className="templogsearch">
          <p>Välj fordon:</p>
          <select value={this.state.selectedfordon} onChange={this.handleDropDownChange}>
            {optionItems}
          </select>
          {/* <DropdownButton  id="dropdown-size-medium">
          {optionItems}
          </DropdownButton> */}
          <br /><br />
          <p>Välj datum:</p>
          <DatePicker id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleChange.bind(this)}
            monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
            dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} /><br />
          <p>Välj starttid:</p>
          <TimePicker start="00:00" end="23:59" step={30} format={24} initialValue="00:00" format={24} value={this.state.starttime} onChange={this.handleTimeChangeStart} />
          <br />
          <p>Välj sluttid:</p>
          <TimePicker start="00:00" end="23:59" step={30} format={24} initialValue="23:00" value={this.state.endtime} onChange={this.handleTimeChangeEnd} />
          <br />

          <Button
            bsStyle="primary" disabled={!isEnabled}
            className="btn-margin"
            onClick={this.getStats.bind(this)}
          >
            Sök
          </Button>
        </div>
        <div className="templogtable">
          <BootstrapTable width='650' data={data} striped={true} hover={true}>
            <TableHeaderColumn width='150' dataField='time' isKey>Tidpunkt</TableHeaderColumn>
            <TableHeaderColumn width='150' dataField='temp2'>Temperatur 2</TableHeaderColumn>
            <TableHeaderColumn width='150' dataField='temp3'>Temperatur 3</TableHeaderColumn>
            <TableHeaderColumn width='150' dataField='temp4'>Temperatur 4</TableHeaderColumn>
            <TableHeaderColumn width='150' dataField='avg'>Medel</TableHeaderColumn>
          </BootstrapTable>

          <br />
          <div className='sweet-loading' align="center">
            <SyncLoader
              color={'#174b5F'}
              loading={this.state.loading}
            />
          </div>
        </div>


      </div>
    );
  }
}

export default TemplogMetrotec;

