import React from 'react';


export const SignIn = (props) => {
    return (
        <div className="container">
            <h4>  {`You're not logged in! Click `}
            <a style={{ cursor: 'pointer' }} onClick={() => props.login()}>
                Login
            </a>
            {` to continue.`}
            </h4> 
        </div>)  
} 