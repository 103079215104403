import React, { Component } from 'react';
import GordoneerVehicleLog from './gordoneervehiclelog';
// import GordoneerComplaints from './gordoneercomplaints';
import Comment from './comment';
import Switch from "react-switch";
// import GordoneerCoopValbo from './gordoneercoopvalbo';
// import GordoneerCoopArlov from './gordoneercooparlov';
// import GordoneerCoopKalmar from './gordoneercoopkalmar';
// import GordoneerCoopLandskrona from './gordoneercooplandskrona';


import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css'

import ReactTable from "react-table";
import 'react-table/react-table.css'

const { Button, Modal, FormControl } = require('react-bootstrap');
var user;

class CartList extends React.Component {
    render() {
        var items = this.props.items.map((item, index) => {
            return (
                <CartListItem key={index} item={item} index={index}
                    removeItem={this.props.removeItem} />
            );
        });
        return (
            <ul className="list-group"> {items} </ul>
        );
    }
}

class CartListItem extends React.Component {
    constructor(props) {
        super(props);
        this.onClickClose = this.onClickClose.bind(this);
        this.onClickDone = this.onClickDone.bind(this);
    }
    onClickClose() {
        var index = parseInt(this.props.index);
        this.props.removeItem(index);
    }
    onClickDone() {
    }
    render() {

        var className = this.props.item.count > 1 ? "fa fa-minus" : "fa fa-times";
        return (
            <li className="list-group-item">
                <div>
                    <span aria-hidden="true"
                        onClick={this.onClickDone}></span>
                    {this.props.item.count} st {this.props.item.value} {this.props.item.size != null && this.props.item.size.length > 0 ? '(' + this.props.item.size + ')' : ''}
                    <button type="button" className="close"
                        onClick={this.onClickClose}><span className={className} /></button>
                </div>
            </li>
        );
    }
}

class CartHeader extends React.Component {
    render() {
        return <h4>Utdelat till förare:</h4>;
    }
}

class GordoneerProfil extends Component {

    componentWillMount() {
        this.createDriver = this.createDriver.bind(this);
        this.deleteDriver = this.deleteDriver.bind(this);
        this.saveItems = this.saveItems.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);

        this.toggleGordoneerChange = this.toggleGordoneerChange.bind(this);
        this.togglePremium = this.togglePremium.bind(this);

        this.setState({ gordoneerapp: false });
        this.setState({
            showModal: false,
            showErrorModal: false,
            showItemsModal: false
        });
        this.setState({ isPoint: false });
        this.setState({ merchants: [] });
        this.setState({ cartItems: [] });
        this.setState({ user: { name: '' } });
        this.setState({ data: [] });
        return { user: user, showModal: false };
    }

    addItem(name, size, count) {

        var exists = false;

        for (let i = 0; i < this.state.cartItems.length; i++) {

            if (this.state.cartItems[i].value === name && this.state.cartItems[i].size === size) {
                exists = true;
                this.state.cartItems[i].count = this.state.cartItems[i].count + count;
            }
        }
        if (!exists) {

            this.state.cartItems.unshift({
                index: this.state.cartItems.length + 1,
                value: name,
                size: size,
                count: count,
                done: false,
            });
        }
        this.setState({ cartItems: this.state.cartItems });
    };

    removeItem(itemIndex) {
        var itemToRemove = this.state.cartItems[itemIndex];

        if (itemToRemove.count > 1) {
            itemToRemove.count = itemToRemove.count - 1;
        }
        else {
            this.state.cartItems.splice(itemIndex, 1);
        }
        this.setState({ cartItems: this.state.cartItems });
    };

    saveItems() {

        var username = localStorage.getItem('user');

        var postbody = {
            'drivername': username,
            'items': []
        };
        this.state.cartItems.forEach(element => {
            postbody.items.push({ name: element.value, size: element.size, count: element.count });
        });
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/driver/driveritems', {
                method: 'PUT',
                body: JSON.stringify(postbody),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => {
                    this.setState({ showItemsModal: false });
                    this.getGordoneerItems();
                });
        }
    };

    createDriver() {

        // if (this.state.gordoneerapp && this.password.value != null && (this.password.value.length <= 8 || !this.hasNumber(this.password.value) ||
        //     !this.hasAlphabetical(this.password.value) || !this.hasUpperCase(this.password.value) || !this.hasLowerCase(this.password.value))) {
        //     alert("Lösenordet måste innehålla både små och stora bokstäver, och siffror, och vara minst 8 tecken långt.")
        //     return;
        // }
        if (this.fullName.value.length == 0 || this.email.value.length == 0 || this.phone.value.length == 0
            || this.timezynkId.value.length == 0) {
            alert("Namn, e-post, telefonnummer och TimeZynk-ID måste anges!")
            return;
        }
        if (this.email.value.indexOf("@") < 0) {
            alert("Det måste vara en giltig e-post adress!")
            return;
        }
        var phone = this.phone.value;
        if (phone != "") {

            if (phone[0] == "0") {
                phone = phone.substring(1, phone.length);
            }
            phone = phone.replace(new RegExp(' ', 'g'), '');
            phone = phone.replace(new RegExp('-', 'g'), '');
            phone = phone.replace(new RegExp('/', 'g'), '');

            if (phone[0] != "+") {
                phone = "+46" + phone;
            }
        }
        var postbody = {
            'id': this.state.user._id,
            'fullName': this.fullName.value,
            'email': this.email.value,
            'phone': phone,
            // 'photo': this.photo.value,
            // 'bringgId': this.bringgId.value,
            // 'hubspotId': this.hubspotId.value,
            'timezynkId': this.timezynkId.value,
            // 'password': this.password.value,
            'gordoneerapp': this.state.gordoneerapp,
            'ispremium': this.state.isPremium
        };
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/driver', {
                method: 'PUT',
                body: JSON.stringify(postbody),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => {

                    this.setState({
                        showModal: false
                    });
                    this.setState({
                        gordoneerapp: false

                    });
                    window.location.assign('driver?id=' + this.state.user._id);
                    // this.getGordoneer();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    hasUpperCase(str) {
        return str.toLowerCase() != str;
    }
    hasLowerCase(str) {
        return str.toUpperCase() != str;
    }
    hasNumber(myString) {
        return /\d/.test(myString);
    }
    hasAlphabetical(myString) {
        if (myString.match(/[a-z]/i)) {
            return true;
        }
        else {
            return false;
        }
    }
    toggleGordoneerChange() {
        this.setState({
            gordoneerapp: !this.state.gordoneerapp
        });
    }

    deleteDriver() {
        if (window.confirm("Du kommer radera föraren och det går inte att ångra. Är du säker på att du vill fortsätta?")) {

        }
        else {
            return;
        }

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/driver/delete/' + this.state.user._id, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => {

                    this.setState({
                        showModal: false
                    });

                    window.location.assign('gordoneers');
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }
    componentDidMount() {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {
            this.setState({ isLoading: true });

        } else {
            console.error("401 Unauthorized");
        }
        this.getGordoneer();
        this.getGordoneerItems();
    }

    getGordoneerItems() {
        var username = localStorage.getItem('user');
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/driver/driveritems?driver=' + username, {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((dataJsonMulti) => {

                    if (dataJsonMulti != null && dataJsonMulti.items != null) {
                        var cartItems = [];
                        for (let index = 0; index < dataJsonMulti.items.length; index++) {
                            const element = dataJsonMulti.items[index];
                            cartItems.push({
                                index: cartItems.length + 1,
                                value: element.name,
                                size: element.size,
                                count: element.count,
                                done: false,
                            });
                        }
                        this.setState({ cartItems: cartItems });
                    }
                });
        }
    };

    getGordoneer() {
        var username = localStorage.getItem('user');
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/users/id/' + username, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((userJson) => {

                    user = userJson;
                    if (user) {

                        this.state.fullName = user.fullName;
                        this.state.email = user.email;
                        this.state.phone = user.phone;
                        this.state.isPremium = user.ispremium;
                        if (user.photo != null && user.photo != "") {
                            this.state.photo = user.photo;
                        }
                        else {
                            user.photo = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBG685vI07-3MsuqJxjCfzIabfFJJG-8yM-ppvjjNpD5QNtWNE4A";
                        }
                        this.state.bringgId = user.bringgId;
                        // this.state.hubspotId = user.external_ref;
                        this.state.timezynkId = user.timezynkId;

                        this.setState({ user: user });
                    }
                    else {
                        this.setState({
                            showErrorModal: true
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });


        } else {
            console.error("401 Unauthorized");
        }
    }
    togglePremium = () => {
        this.setState({
            isPremium: !this.state.isPremium,
        });
    }
    open() {
        this.setState({
            showModal: true
        });
    }
    openItemsModal() {
        this.setState({
            showItemsModal: true
        });
    }
    cancel() {
        this.setState({
            showModal: false,
            gordoneerapp: false
        });
        this.getGordoneer();
    }

    cancelError() {
        this.setState({
            showErrorModal: false
        });
    }

    cancelItems() {
        this.setState({
            showItemsModal: false,
        });
        this.getGordoneerItems();
    }

    render() {
        // const passworddisabled = !this.state.gordoneerapp;
        const merchants = this.state.merchants;
        const ordersTabs = [];

        // if (merchants.indexOf("Linas Matkasse") >= 0) {
        //     ordersTabs.push({ name: 'Linas Matkasse', content: <GordoneerLinasMatkasse /> })
        // }
        // if (merchants.indexOf("Svarta Lådan") >= 0) {
        //     ordersTabs.push({ name: 'Svarta Lådan', content: <GordoneerSvartaLadan /> })
        // }
        // if (merchants.indexOf("Wrapsons") >= 0) {
        //     ordersTabs.push({ name: 'Wrapsons', content: <GordoneerWrapsons /> })
        // }
        // if (merchants.indexOf("Willys Älvsjö") >= 0) {
        //     ordersTabs.push({ name: 'Willys Älvsjö', content: <GordoneerWillys /> })
        // }
        // if (merchants.indexOf("Willys Umeå") >= 0) {
        //     ordersTabs.push({ name: 'Willys Umeå', content: <GordoneerWillysUmea /> })
        // }
        // if (merchants.indexOf("Willys Växjö") >= 0) {
        //     ordersTabs.push({ name: 'Willys Växjö', content: <GordoneerWillysVaxjo /> })
        // }
        // if (merchants.indexOf("Willys Gävle") >= 0) {
        //     ordersTabs.push({ name: 'Willys Gävle', content: <GordoneerWillysGavle /> })
        // }
        // if (merchants.indexOf("Willys Jönköping") >= 0) {
        //     ordersTabs.push({ name: 'Willys Jönköping', content: <GordoneerWillysJonkoping /> })
        // }
        // if (merchants.indexOf("Willys Länna") >= 0) {
        //     ordersTabs.push({ name: 'Willys Länna', content: <GordoneerWillysLanna /> })
        // }
        // if (merchants.indexOf("Willys Falun") >= 0) {
        //     ordersTabs.push({ name: 'Willys Falun', content: <GordoneerWillysFalun /> })
        // }
        // if (merchants.indexOf("Willys Eskilstuna") >= 0) {
        //     ordersTabs.push({ name: 'Willys Eskilstuna', content: <GordoneerWillysEskilstuna /> })
        // }
        // if (merchants.indexOf("Willys Uppsala") >= 0) {
        //     ordersTabs.push({ name: 'Willys Uppsala', content: <GordoneerWillysUppsala /> })
        // }
        // if (merchants.indexOf("Willys Västerås") >= 0) {
        //     ordersTabs.push({ name: 'Willys Västerås', content: <GordoneerWillysVasteras /> })
        // }
        // if (merchants.indexOf("Hemköp Linköping") >= 0) {
        //     ordersTabs.push({ name: 'Hemköp Linköping', content: <GordoneerHemkopLinkoping /> })
        // }
        // if (merchants.indexOf("Hemköp Nacka") >= 0) {
        //     ordersTabs.push({ name: 'Hemköp Nacka', content: <GordoneerHemkopNacka /> })
        // }
        // if (merchants.indexOf("Hemköp Djurgårdsstaden") >= 0) {
        //     ordersTabs.push({ name: 'Hemköp Djurgårdsstaden', content: <GordoneerHemkop /> })
        // }
        // if (merchants.indexOf("Stora Coop Kalmar") >= 0) {
        //     ordersTabs.push({ name: 'Coop Kalmar', content: <GordoneerCoopKalmar /> })
        // }
        // if (merchants.indexOf("Coop Forum Arlöv") >= 0) {
        //     ordersTabs.push({ name: 'Coop Forum Arlöv', content: <GordoneerCoopArlov /> })
        // }
        // if (merchants.indexOf("Coop Valbo Gävle") >= 0) {
        //     ordersTabs.push({ name: 'Coop Valbo Gävle', content: <GordoneerCoopValbo /> })
        // }
        // if (merchants.indexOf("Coop Landskrona") >= 0) {
        //     ordersTabs.push({ name: 'Coop Landskrona', content: <GordoneerCoopLandskrona /> })
        // }
        // if (merchants.indexOf("Min Farm") >= 0) {
        //     ordersTabs.push({ name: 'Min Farm', content: <GordoneerMinFarm /> })
        // }
        // if (merchants.indexOf("SÄBB Catering") >= 0) {
        //     ordersTabs.push({ name: 'SÄBB Catering', content: <GordoneerSabb /> })
        // }
        function getTabs() {
            return ordersTabs.map(ordertab => ({
                tabClassName: 'tab', // Optional
                panelClassName: 'panel', // Optional
                title: ordertab.name,
                getContent: () => ordertab.content,
            }));
        }

        return (
            <div className="container">
                <div className="container target">
                    <div className="row">
                        <div className="col-sm-2">
                            <h2>{this.state.fullName}</h2>

                            <button type="button" onClick={this.open.bind(this)} className="btn btn-warning">Edit</button> { }
                            <button type="button" onClick={this.deleteDriver.bind(this)} className="btn btn-danger">Delete</button>
                            <br /><br />
                        </div>
                        <div className="col-sm-8">
                            {
                                this.state.isPremium && (
                                    <div>
                                        <img title="profile image" width='100' className="img-circle img-responsive profileimg" src='./images/badge.png' />
                                        <p>Premium-Gordoner</p>
                                    </div>
                                )}
                        </div>

                        <div className="col-sm-2">
                            <i className="pull-sright">

                                <img title="profile image" className="img-circle img-responsive profileimg" src={this.state.user.photo} />
                            </i>

                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-3">
                            <ul className="list-group">
                                <li className="list-group-item text-muted" contenteditable="false">Profil</li>
                                <li className="list-group-item text-right"><span className="pull-left"><strong className="">Nam: </strong></span> {this.state.fullName}</li>
                                <li className="list-group-item text-right"><span className="pull-left"><strong className="">Telefon: </strong></span>{this.state.phone}</li>
                                <li className="list-group-item text-right"><span className="pull-left"><strong className="">Email: </strong></span>{this.state.email}</li>
                                <li className="list-group-item text-right"><span className="pull-left"><strong className="">Timezynk ID: </strong></span>{this.state.timezynkId}</li>
                            </ul>
                            <div className="panel panel-default">

                                <div className="panel-heading">Leveransprecision:</div>
                                <div className="panel-body">
                                    <div className="row overview">
                                        <div className="col-md-4 user-pad text-center">
                                            <h4>Aktuell månad</h4>
                                            <h5>{this.state.user.currentmonthleveransprecision}%</h5>
                                        </div>
                                        <div className="col-md-4 user-pad text-center">
                                            <h4>Förra månaden</h4>
                                            <h5>{this.state.user.previousmonthleveransprecision}%</h5>
                                        </div>
                                        <div className="col-md-4 user-pad text-center">
                                            <h4>Totalt<br /> <br /> </h4>
                                            <h5>{this.state.user.leveransprecision}%</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="panel panel-default">

                                <div className="panel-heading">Betyg:</div>
                                <div className="panel-body">
                                    <div className="row overview">
                                        <div className="col-md-6 user-pad text-center">
                                            <h4>Snittbetyg</h4>
                                            <h5>{this.state.user.average_rating}</h5>
                                        </div>

                                        <div className="col-md-6 user-pad text-center">
                                            <h4>Antal</h4>
                                            <h5>{this.state.user.num_ratings}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">

                                <div className="panel-heading">Gordoneer-kit:</div>
                                <div className="panel-body">
                                    <ul className="list-group">
                                        {this.state.cartItems.map((item, i) =>
                                            <li className="list-group-item text-right"><span className="pull-left"><strong className="">{item.count} st {item.value}</strong></span>{item.size != null && item.size != "" ? item.size : "-"}</li>

                                        )}

                                    </ul>

                                    <button type="button" onClick={this.openItemsModal.bind(this)} className="btn btn-warning">Redigera</button>
                                </div>
                            </div>

                            <div className="panel panel-default">

                                <div className="panel-heading">Interna kommentarer:</div>
                                <div className="panel-body">
                                    <Comment />
                                </div>
                            </div>

                            {/* <div className="panel panel-default">
                                <div className="panel-heading">Leveransstatus   <i className="fa fa-thermometer-three-quarters fa-1x"></i>

                                </div>
                                <div className="panel-body"><a href="http://bootply.com" className="">~Non-compliance to certain checklist items~</a>

                                </div>
                            </div> */}

                        </div>
                        <div className="col-sm-9" contenteditable="false">

                            <div className="panel panel-default">
                                <div className="panel-heading"><b>Leveransstatus senaste veckan</b></div>
                                <div className="panel-body">
                                    <ReactTable
                                        noDataText="Oh Noes!"
                                        data={this.state.data}
                                        columns={[

                                            {
                                                Header: 'Datum',
                                                accessor: 'datum'
                                            },
                                            {
                                                Header: 'Butik',
                                                accessor: 'kund',
                                            },
                                            {
                                                Header: 'Antal',
                                                accessor: 'antal'
                                            },
                                            {
                                                Header: 'Leveransprecision',
                                                accessor: 'precision',
                                                Cell: row => (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: '#dadada',
                                                            borderRadius: '2px'
                                                        }}
                                                    >

                                                        <div
                                                            style={{
                                                                width: `${row.value}%`,
                                                                height: '100%',
                                                                backgroundColor: row.value > 90 ? '#228B22'
                                                                    : row.value > 70 ? '#FFA500'
                                                                        : '#fe0400',
                                                                borderRadius: '2px',
                                                                transition: 'all .2s ease-out'
                                                            }}
                                                        >
                                                            <span style={{
                                                                color: '#ffffff',
                                                                paddingLeft: '10px'

                                                            }}>
                                                                {row.value}%
                  </span>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        ]}
                                        defaultPageSize={8}
                                        filterable={true}
                                        defaultSorted={[{
                                            id: 'datum',
                                            desc: true,
                                        }]}
                                        className="-striped -highlight"
                                    />
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading"><b>Uppdrag</b></div>
                                {/* <Tabs items={getTabs()} /> */}
                            </div>

                            <div className="panel panel-default eventlogpanel">
                                <div className="panel-heading"><b>Händelser</b></div>
                                {/* <GordoneerComplaints /> */}
                            </div>

                            <div className="panel panel-default vehiclelogpanel">
                                <div className="panel-heading"><b>Fordonslogg</b></div>
                                <GordoneerVehicleLog />

                            </div>

                            {/*
                            <div className="panel panel-default target">
                                <div className="panel-heading" contenteditable="false"><b>Treatments and Medications Needed</b></div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="thumbnail">
                                                <img alt="300x200" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/UPMCEast_CTscan.jpg/280px-UPMCEast_CTscan.jpg" />
                                                <div className="caption">
                                                    <h3>
                                                        Computed Tomography Scan
							</h3>
                                                    <p>
                                                        For potential transcatheter repair and replacement of heart valves
							</p>
                                                    <p>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="thumbnail">
                                                <img alt="300x200" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEQz__ycuhuAoNISn3rNWuaZhhzH4lAnPg0IvmQXJpkN08pC5oZA" />
                                                <div className="caption">
                                                    <h3>
                                                        Counselling Service
							</h3>
                                                    <p>
                                                        For stability of emotional threshold throughout the battery of tests
							</p>
                                                    <p>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="thumbnail">
                                                <img alt="300x200" src="https://assets.nhs.uk/prod/images/C0097883.2e16d0ba.fill-920x613.jpg" />
                                                <div className="caption">
                                                    <h3>
                                                        Cardiac Catheterization
							</h3>
                                                    <p>
                                                        For further evaluation of other implicitly acquired cardiovascular conditions
							</p>
                                                    <p>

                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading"><b>Daily Medication and Treatment Breakdown:</b></div>
                                <div className="panel-body"> Textual, descriptive form of the above checklist
                <br /><button type="button" className="btn btn-primary">Generate Report</button>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Modal bsSize="small" show={
                    this.state.showErrorModal
                }
                    onHide={
                        this.cancelError.bind(this)
                    } >
                    <Modal.Header >
                        <Modal.Title>Driver is not found</Modal.Title> </Modal.Header >
                    <Modal.Body  >
                        <p>The driver is not found in the Gordon Portal.</p>
                    </Modal.Body> <Modal.Footer>
                        <Button onClick={
                            this.cancelError.bind(this)
                        } > OK </Button>
                    </Modal.Footer> </Modal>

                <Modal bsSize="large" show={
                    this.state.showModal
                }
                    onHide={
                        this.cancel.bind(this)
                    } dimmer={"blurring"}>
                    <Modal.Header >
                        <Modal.Title>Edit Driver</Modal.Title> </Modal.Header >
                    <Modal.Body  >

                        <p>Driver Name (first and last name):</p>
                        <FormControl inputRef={
                            (ref) => {
                                this.fullName = ref
                            }
                        } placeholder='Namn' defaultValue={this.state.fullName} />
                        <br />
                        <p>E-mail:</p>
                        <FormControl inputRef={
                            (ref) => {
                                this.email = ref
                            }
                        } placeholder='E-post' defaultValue={this.state.email} />
                        <br />
                        <p>Mobile phone:</p>
                        <FormControl inputRef={
                            (ref) => {
                                this.phone = ref
                            }
                        } placeholder='Enter mobile phone with country code (+46, +45 etc)' defaultValue={this.state.phone} />
                        <br />
                        {/* <p>Hubspot ID:</p>
                        <FormControl type="number" inputRef={
                            (ref) => {
                                this.hubspotId = ref
                            }
                        } placeholder='Hubspot ID' defaultValue={this.state.hubspotId} />
                        <br /> */}
                        {/* <p>BringgId ID:</p>
                        <FormControl type="number" inputRef={
                            (ref) => {
                                this.bringgId = ref
                            }
                        } placeholder='Bringg ID' defaultValue={this.state.bringgId} />
                        <br /> */}
                        <p>TimeZynk ID (Go to timezynk.com, find the driver, and copy the id/text from the url. See image below as example):</p>
                        <img src="images/timezynk1.png" width="500" />
                        <FormControl
                            defaultValue={this.state.timezynkId}
                            inputRef={
                                (ref) => {
                                    this.timezynkId = ref
                                }
                            } placeholder='TimeZynk ID' />
                        <br />
                    
                        {/* <p>Premium-Gordoneer:</p>
                        <Switch
                            onChange={this.togglePremium}
                            checked={this.state.isPremium}
                            id="normal-switch"
                        />
                        <br />
                        <br /> */}
                        {/* <p>Bild url:</p>
                        <FormControl inputRef={
                            (ref) => {
                                this.photo = ref
                            }
                        } placeholder='Url till bild' defaultValue={this.state.photo} />
                        <br /> */}
                        <p><input type='checkbox' checked={this.state.gordoneerapp} onChange={this.toggleGordoneerChange.bind(this)} />
                            &nbsp;&nbsp;<span onClick={this.toggleGordoneerChange.bind(this)}>Add/update driver to Gordoneer.com (The password will be generated and sent in an email to the driver)</span></p>
                        <br />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button negative onClick={this.cancel.bind(this)}>
                            Cancel
        </Button>
                        <Button positive
                            bsStyle="default"
                            onClick={this.createDriver.bind(this)}  >Save</Button>
                    </Modal.Footer>
                </Modal>


                <Modal bsSize="large" show={
                    this.state.showItemsModal
                }
                    onHide={
                        this.cancelItems.bind(this)
                    } dimmer={"blurring"}>
                    <Modal.Header >
                        <Modal.Title>Redigera Driver-kit</Modal.Title> </Modal.Header >
                    <Modal.Body  >
                        {/* <h2>Välj Gordoneer-produkt:</h2> */}
                        <div className="products" >
                            <div className="product" onClick={this.addItem.bind(this, "Jacka", "XS", 1)} >
                                <h4>Jacka (XS)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Jacka", "S", 1)} >
                                <h4>Jacka (S)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Jacka", "M", 1)} >
                                <h4>Jacka (M)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Jacka", "L", 1)} >
                                <h4>Jacka (L)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Jacka", "XL", 1)} >
                                <h4>Jacka (XL)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Jacka", "XXL", 1)} >
                                <h4>Jacka (XXL)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "T-Shirt", "XS", 1)} >
                                <h4>T-shirt (XS)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "T-Shirt", "S", 1)} >
                                <h4>T-shirt (S)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "T-Shirt", "M", 1)} >
                                <h4>T-shirt (M)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "T-Shirt", "L", 1)} >
                                <h4>T-shirt (L)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "T-Shirt", "XL", 1)} >
                                <h4>T-shirt (XL)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "T-Shirt", "XXL", 1)} >
                                <h4>T-shirt (XXL)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Byxa", "S", 1)} >
                                <h4>Byxa (S)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Byxa", "M", 1)} >
                                <h4>Byxa (M)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Byxa", "L", 1)} >
                                <h4>Byxa (L)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Byxa", "XL", 1)} >
                                <h4>Byxa (XL)</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Mössa", "", 1)} >
                                <h4>Mössa</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Handskar", "", 1)} >
                                <h4>Handskar</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Gympapåse", "", 1)} >
                                <h4>Gympapåse</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Mobilhållare", "", 1)} >
                                <h4>Mobilhållare</h4>
                            </div>
                            <div className="product" onClick={this.addItem.bind(this, "Adapter", "", 1)} >
                                <h4>Adapter</h4>
                            </div>
                        </div>
                        <br />
                        <div className="cart">
                            {
                                (this.state.cartItems != null && this.state.cartItems.length > 0) && (
                                    <div id="main">
                                        <br /><br />
                                        <CartHeader />
                                        <CartList items={this.state.cartItems}
                                            removeItem={this.removeItem} />

                                    </div>

                                )
                            }
                            {
                                (this.state.cartItems == null || this.state.cartItems.length === 0) && (
                                    <div id="main" className="paddingtop">
                                        <p>Information saknas</p>
                                    </div>
                                )
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button negative onClick={this.cancelItems.bind(this)}>Avbryt</Button>
                        <Button positive
                            bsStyle="default"
                            onClick={this.saveItems.bind(this)}  >Spara</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default GordoneerProfil;

