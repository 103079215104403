import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

var fordon;
class Fleet extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {


    this.setState({ fordon: {} });
    return { fordon: fordon };
  }
  componentDidMount() {

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/vehicle/full', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          fordon = dataJson;
          this.state.fordon = fordon;
          this.setState({ fordon: fordon });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  fleetFormatter(cell, row) {
    const link = 'fleetinfo?regnr=' + cell;
    return ' <a href="' + link + '">' + cell + '</a>';
  }


  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Fordon</h3>
              <div className="fordonDiv" >
                <BootstrapTable width="200" data={fordon} striped={true} hover={true} >
                  <TableHeaderColumn width="200" isKey dataField='registration' dataFormat={this.fleetFormatter}>Registreringsnummer</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Fleet;

